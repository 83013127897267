import moment from "moment";
import { Cookies } from "react-cookie-consent";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { ENUMS } from "../Constants";
import { DecryptPassword } from "./DashboardServices";
import { Login, VerifyClientLogin } from "./LoginServices";
import Moment from "moment";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
const CryptoJS = require("crypto-js");
const cryptoKeyClientLogin = "adminclientlogin";
const cryptoKey = "SqT_InntelligentCRM_kamlesh_393987";

export const AppConfigData = {
  APIBasePath: process.env.REACT_APP_APIBasePath,
  ClientBasePath: process.env.REACT_APP_ClientBasePath,
  GetThread: process.env.REACT_APP_GetThread,
};

//#region  Handle Common Validations
const getRightCommonFun = (module_name) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dataSelector: any = useSelector((state: any) => state.session);

  let right_json = {
    is_view: true,
    is_create: true,
    is_delete: true,
    is_update: true,
    is_export: true,
  };
  let resData =
    dataSelector.user_rights &&
    dataSelector.user_rights.filter((itm) => itm.RightName == module_name);
  if (resData && resData.length) {
    right_json = {
      is_view: resData[0].IsView,
      is_create: resData[0].IsCreate,
      is_delete: resData[0].IsDelete,
      is_update: resData[0].IsEdit,
      is_export: resData[0].IsExport,
    };
  }
  return right_json;
};

function uploadAdapter(loader) {
  return {
    upload: () => {
      return new Promise((resolve, reject) => {
        let baseURL: any = "";
        const body = new FormData();
        loader.file.then((file) => {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            baseURL = reader.result;
            resolve({
              default: baseURL,
            });
          };
        });
      });
    },
  };
}

export const CommonService = {
  unauthorizedAction: () => {
    Swal.fire({
      title: "Unauthorized!",
      text: "It appears that you don't have permission to access this.",
      icon: "error",
      confirmButtonText: "Close",
    });
  },
  getPageRight: (module_name) => {
    return getRightCommonFun(module_name);
  },
  uploadPlugin: (editor) => {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  },
  convertUTCToLocal: (date, format = "") => {
    let localDate = "";
    if (date !== null && date !== "" && date !== undefined) {
      // let stillUtc = Moment.utc(date).toDate();
      localDate = Moment(date)
        .local()
        .format(format != "" ? format : "MM-DD-YYYY");
    }
    return localDate;
  },
};

function checkKeysAvailability(str) {
  if (
    !str.includes("chkin") ||
    !str.includes("chkout") ||
    !str.includes("expediaPropertyId") ||
    !str.includes("searchId") ||
    !str.includes("hotels.com")
  ) {
    return false;
  } else {
    return true;
  }
}

export function fnCheckValidationOfObject(obj) {
  let IsValid = true;
  if (obj.errors !== null) {
    if (obj.errors.ValidationRules) {
      for (let i = 0; i < obj.errors.ValidationRules.length; i++) {
        obj.errors[obj.errors.ValidationRules[i].FieldName] = "";
      }

      for (let i = 0; i < obj.errors.ValidationRules.length; i++) {
        let objRules = obj.errors.ValidationRules[i];
        if (objRules !== null) {
          if (objRules.ValidationType.toLowerCase() === "required") {
            if (
              obj[objRules.FieldName] === "" ||
              obj[objRules.FieldName] === null ||
              obj[objRules.FieldName] === undefined
            ) {
              if (objRules.FieldName === "activityTime") {
                if (
                  (obj.emailSendTo !== null &&
                    obj.emailSendTo !== "" &&
                    obj.emailSendTo !== undefined) ||
                  obj.activityType_Term.includes("Blitz") ||
                  obj.activityType_Term.includes("Client Visit") ||
                  obj.activityType_Term.includes("Meeting") ||
                  obj.activityType_Term.includes("Networking") ||
                  obj.activityType_Term.includes("Site Visit")
                ) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                } else {
                  IsValid = true;
                }
              } else if (objRules.FieldName === "strDuration") {
                if (
                  obj.activityType_Term.includes("Blitz") ||
                  obj.activityType_Term.includes("Client Visit") ||
                  obj.activityType_Term.includes("Meeting") ||
                  obj.activityType_Term.includes("Networking") ||
                  obj.activityType_Term.includes("Site Visit")
                ) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                } else {
                  IsValid = true;
                }
              } else if (objRules.FieldName === "GmailEmailID") {
                if (obj.GmailEmailID) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                } else {
                  IsValid = true;
                }
              } else if (objRules.FieldName === "GmailPassword") {
                if (obj.GmailEmailID) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                } else {
                  IsValid = true;
                }
              } else if (objRules.FieldName === "SMTPIncommingServer") {
                if (obj.GmailEmailID) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                } else {
                  IsValid = true;
                }
              } else if (objRules.FieldName === "SMTPOutgoingServer") {
                if (obj.GmailEmailID) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                } else {
                  IsValid = true;
                }
              } else if (objRules.FieldName === "IncommingPort") {
                if (obj.GmailEmailID) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                } else {
                  IsValid = true;
                }
              } else if (objRules.FieldName === "OutgoingPort") {
                if (obj.GmailEmailID) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                } else {
                  IsValid = true;
                }
              } else {
                IsValid = false;
                obj.errors[objRules.FieldName] = objRules.ValidationMessage;
              }
            }
          }

          if (
            obj[objRules.FieldName] !== "" &&
            obj[objRules.FieldName] !== null &&
            obj[objRules.FieldName] !== undefined
          ) {
            //   if (objRules.FieldName === "activityTime") {
            //     IsValid= true;
            //   //   if (obj.activityType_Term.includes("Blitz")) {
            //   //     IsValid= false;
            //   //     obj.errors[objRules.FieldName] = objRules.ValidationMessage;
            //   // }else{
            //   //
            //   // }
            // } else {
            //   IsValid= false
            // }

            if (objRules.ValidationType.toLowerCase() === "mobile-mask") {
              if (!obj[objRules.FieldName].match(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/)) {
                IsValid = false;
                obj.errors[objRules.FieldName] = objRules.ValidationMessage;
              }
            }

            if (
              obj.errors[objRules.FieldName] === "" &&
              objRules.ValidationType.toLowerCase() === "email"
            ) {
              var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
              if (obj[objRules.FieldName].slice(-19) !== "@houston.nae.school") {
                if (!obj[objRules.FieldName].match(mailformat)) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              } else {
                let emailFormate = /^[@.a-zA-Z1-9]+$/;
                if (!obj[objRules.FieldName].match(emailFormate)) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
            }

            // Category Dropdown Validation
            if (objRules.FieldName === "category") {
              if (obj.category === "Select Category") {
                IsValid = false;
                obj.errors[objRules.FieldName] = objRules.ValidationMessage;
              }
            }

            if (objRules.FieldName === "businessTypes_Term") {
              if (obj.businessTypes_Term === "Select Business Type") {
                IsValid = false;
                obj.errors[objRules.FieldName] = objRules.ValidationMessage;
              }
            }

            //  Range validation
            if (objRules.ValidationType.toLowerCase() === "range") {
              if (objRules.FieldName === "invoiceContactPhone") {
                if (obj.invoiceContactPhone.trim().replaceAll("-", "").length < 10) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }

              if (objRules.FieldName === "contactNo") {
                if (obj.contactNo.trim().replaceAll("-", "").length < 10) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
              if (objRules.FieldName === "priContactNo") {
                if (obj.priContactNo.trim().replaceAll("-", "").length < 10) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
              if (objRules.FieldName === "phone") {
                if (obj.phone.trim().replaceAll("-", "").length < 10) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
              if (objRules.FieldName === "ContactNo") {
                if (obj.ContactNo.trim().replaceAll("-", "").length < 10) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
              if (objRules.FieldName === "telephoneNo") {
                if (obj.telephoneNo.trim().replaceAll("-", "").length < 10) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
              if (objRules.FieldName === "mobileNo") {
                if (obj.mobileNo.trim().replaceAll("-", "").length < 10) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
              if (objRules.FieldName === "contactMobNo") {
                if (obj.contactMobNo.trim().replaceAll("-", "").length < 10) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
              if (objRules.FieldName === "headPhoneNo") {
                if (obj.headPhoneNo.trim().replaceAll("-", "").length < 10) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
              if (objRules.FieldName === "travelAgencyPhone") {
                if (obj.travelAgencyPhone.trim().replaceAll("-", "").length < 10) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
              if (objRules.FieldName === "rateCode") {
                if (obj.rateCode.length > 15) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
              if (objRules.FieldName === "approvedRMRate") {
                if (obj.approvedRMRate.length > 15) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
              if (objRules.FieldName === "citySpend") {
                if (obj.citySpend.length > 15) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
              if (objRules.FieldName === "citySpendRNS") {
                if (obj.citySpendRNS.length > 15) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
              if (objRules.FieldName === "ttmHotelligence") {
                if (obj.ttmHotelligence.length > 15) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
              if (objRules.FieldName === "hotelligenceRNS") {
                if (obj.hotelligenceRNS.length > 15) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
              if (objRules.FieldName === "hotelligenceADR") {
                if (obj.hotelligenceADR.length > 15) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
              if (objRules.FieldName === "rate1") {
                if (obj.rate1.length > 15) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
              if (objRules.FieldName === "rate2") {
                if (obj.rate2.length > 15) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
              if (objRules.FieldName === "clientCode") {
                if (obj.clientCode.length > 15) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
              if (objRules.FieldName === "HTARGETBAR") {
                if (obj.HTARGETBAR.length > 15) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
              if (objRules.FieldName === "HTARGETLNR") {
                if (obj.HTARGETLNR.length > 15) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
            }
            if (objRules.FieldName === "RateShopURL") {
              if (objRules.ValidationType.toLowerCase() === "invalidurl") {
                let isValid = checkKeysAvailability(obj.RateShopURL);
                if (!isValid) {
                  IsValid = false;
                  obj.errors[objRules.FieldName] = objRules.ValidationMessage;
                }
              }
            }
          }

          if (
            obj.errors[objRules.FieldName] === "" &&
            objRules.ValidationType.toLowerCase() === "min" &&
            objRules.Min !== null &&
            objRules.Min !== undefined &&
            objRules.Min !== ""
          ) {
            if (
              obj[objRules.FieldName] !== null &&
              obj[objRules.FieldName] !== undefined &&
              obj[objRules.FieldName] !== ""
            ) {
              if (parseFloat(obj[objRules.FieldName]) < objRules.Min) {
                IsValid = false;
                obj.errors[objRules.FieldName] = objRules.ValidationMessage.replace(
                  "{0}",
                  objRules.Min
                );
              }
            }
          }

          if (
            obj.errors[objRules.FieldName] === "" &&
            objRules.ValidationType.toLowerCase() === "max" &&
            objRules.Max !== null &&
            objRules.Max !== undefined &&
            objRules.Max !== ""
          ) {
            if (
              obj[objRules.FieldName] !== null &&
              obj[objRules.FieldName] !== undefined &&
              obj[objRules.FieldName] !== ""
            ) {
              if (parseFloat(obj[objRules.FieldName]) > objRules.Max) {
                IsValid = false;
                obj.errors[objRules.FieldName] = objRules.ValidationMessage.replace(
                  "{0}",
                  objRules.Max
                );
              }
            }
          }

          if (
            obj.errors[objRules.FieldName] === "" &&
            objRules.ValidationType.toLowerCase() === "minmax" &&
            objRules.Max !== null &&
            objRules.Max !== undefined &&
            objRules.Max !== "" &&
            objRules.Min !== null &&
            objRules.Min !== undefined &&
            objRules.Min !== ""
          ) {
            if (
              obj[objRules.FieldName] !== null &&
              obj[objRules.FieldName] !== undefined &&
              obj[objRules.FieldName] !== ""
            ) {
              if (
                parseFloat(obj[objRules.FieldName]) < objRules.Min ||
                parseFloat(obj[objRules.FieldName]) > objRules.Max
              ) {
                IsValid = false;
                obj.errors[objRules.FieldName] = objRules.ValidationMessage.replace(
                  "{0}",
                  objRules.Min
                ).replace("{1}", objRules.Max);
              }
            }
          }
        }
      }
    }
  }
  return { isValid: IsValid, obj: obj };
}

//This Function For Multiple Object For Validation
// function fnCheckValidationOfList(list) {
//   let IsValid = true;
//   for (let i = 0; i < list.length; i++) {
//     let obj = fnCheckValidationOfObject(list[i]);
//     list[i] = obj;
//     if (!IsValid) {
//       IsValid = obj.isValid;
//     }
//   }
//   return { isValid: IsValid, obj: list };
// }
//#endregion
//#region  Toaster Notifications

export function ToasterSuccess(Value) {
  toast.success(Value, {
    autoClose: 1000,
    pauseOnFocusLoss: false,
    theme: "colored",
  });
}
export function ToasterError(Value) {
  toast.error(Value, {
    autoClose: 2000,
    pauseOnFocusLoss: false,
    theme: "colored",
  });
}

//#endregion

export function getDateInFormatMMMMDYYYY(date) {
  if (date === "1900-01-01T00:00:00") {
    return "-";
  } else if (date !== null && date !== "" && date !== undefined) {
    return moment(date).format("MMMM-D-YYYY");
  } else {
    return "";
  }
}
export function getDateInFormat(date) {
  if (date === "1900-01-01T00:00:00") {
    return "-";
  } else if (date !== null && date !== "" && date !== undefined) {
    return moment(date).format(
      Cookies.get("SystemSettingDate")
        ? Cookies.get("SystemSettingDate").toUpperCase()
        : "MM-DD-YYYY"
    );
  } else {
    return "";
  }
}

export function getDateFormatIn_MonthName_Date(date) {
  if (date === "1900-01-01T00:00:00") {
    return "-";
  } else if (date !== null && date !== "" && date !== undefined) {
    return moment(date).format("MMMM DD");
  } else {
    return "";
  }
}

export function getTimeInFormat(date) {
  let xu = Cookies.get("SystemSettingTime");
  if (date !== null && date !== "" && date !== undefined) {
    // return Moment(date).format(Cookies.get("SystemSettingTime").replace("aa", "a")
    let timeBev = CommonService.convertUTCToLocal(date, xu);

    if (timeBev !== "" && timeBev) {
      let timeBevAr = timeBev.split(":");
      let remCountBev: any = timeBevAr[0];
      let time_type_bev = "AM";
      if (parseInt(timeBevAr[0]) > 12) {
        remCountBev = parseInt(timeBevAr[0]) - 12;
        time_type_bev = "PM";
      } else if (parseInt(timeBevAr[0]) == 12 || parseInt(timeBevAr[0]) == 12) {
        remCountBev = 12;
        if (parseInt(timeBevAr[0]) == 12) {
          time_type_bev = "PM";
        } else {
          time_type_bev = "AM";
        }
      } else {
        //This is applied by heena, for if {parseInt(timeBevAr[0])} == 0.
        if (parseInt(timeBevAr[0]) == 0 || parseInt(timeBevAr[0]) == 0) {
          remCountBev = 12;
        }
      }
      if (
        timeBevAr[1] &&
        timeBevAr[1] != undefined &&
        (timeBevAr[1].includes("AM") ||
          timeBevAr[1].includes("am") ||
          timeBevAr[1].includes("PM") ||
          timeBevAr[1].includes("pm"))
      ) {
        timeBev = remCountBev.toString().padStart(2, "0") + ":" + timeBevAr[1];
      } else {
        timeBev =
          remCountBev.toString().padStart(2, "0") + ":" + timeBevAr[1] + " " + time_type_bev;
      }
    }
    return timeBev;
  } else {
    return "";
  }
}

export function ConvertMeridiemToTwoFourDate(time: any, date: any) {
  let timeStr: any = "";
  let dateWithISO: any = null;

  if (time && time !== "") {
    if (time.includes("PM")) {
      let strArray = time.replaceAll("PM", "").replaceAll(" ", "").trim().split(":");
      timeStr =
        parseInt(strArray[0]) === 12
          ? strArray[0] + ":" + strArray[1]
          : parseInt(strArray[0]) + 12 + ":" + strArray[1];
    } else {
      let strArrayAM = time.replaceAll("AM", "").replaceAll(" ", "").trim().split(":");
      if (parseInt(strArrayAM[0]) === 12) {
        timeStr =
          (parseInt(strArrayAM[0]) === 12 ? "00" : parseInt(strArrayAM[0]) + 12) +
          ":" +
          strArrayAM[1];
      } else {
        timeStr = time.replaceAll("AM", "").replaceAll(" ", "").trim();
      }
    }
    dateWithISO = date + " " + timeStr + ":00";
    return dateWithISO;
    // dateWithISO = new Date(date + "T" + timeStr + ":00");

    // dateWithISO.setHours(dateWithISO.getHours() + 5);
    // dateWithISO.setMinutes(dateWithISO.getMinutes() + 30);

    // return dateWithISO.toISOString();
  } else {
    return "";
  }
}

export const getBase64 = (file) => {
  return new Promise((resolve) => {
    let baseURL: any = "";
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
};

// Handle Login As Client From Admin :
export const handleClientLogin = async (username, pwd, token, clientid) => {
  localStorage.clear();
  Cookies.remove("SystemSettingDate");
  // caches.keys().then((names) => {
  //   names.forEach((name) => {
  //     caches.delete(name);
  //   });
  // });
  var bytes_ur = CryptoJS.AES.decrypt(username.replaceAll(" ", "+"), cryptoKeyClientLogin);
  let username_dy = JSON.parse(bytes_ur.toString(CryptoJS.enc.Utf8));

  var bytes_pwd = CryptoJS.AES.decrypt(pwd.replaceAll(" ", "+"), cryptoKeyClientLogin);
  let pwd_dy = JSON.parse(bytes_pwd.toString(CryptoJS.enc.Utf8));
  let encodepwd_dy = encodeURIComponent(pwd_dy);

  var bytes_token = CryptoJS.AES.decrypt(token.replaceAll(" ", "+"), cryptoKeyClientLogin);
  let token_dy = JSON.parse(bytes_token.toString(CryptoJS.enc.Utf8));

  var bytes_clientid = CryptoJS.AES.decrypt(clientid.replaceAll(" ", "+"), cryptoKeyClientLogin);
  let clientid_dy = JSON.parse(bytes_clientid.toString(CryptoJS.enc.Utf8));

  let val_time = new Date(token_dy);
  let cur_time = new Date();
  val_time.setMinutes(val_time.getMinutes() + 1);

  if (cur_time < val_time) {
    let resPass = await DecryptPassword(encodepwd_dy);

    if (typeof resPass.data !== "undefined" && resPass.data) {
      if (resPass.data.success) {
        let input = {
          userName: username_dy,
          password: resPass.data.data,
          clientID: null,
        };

        const res: any = await Login(input);
        if (res.data.statusCode === 200) {
          const data: any = res.data.data && res.data.data.dataList1;
          var Value = data;
          if (clientid_dy) {
            Value = data.filter((itm) => itm.C_ClientID === clientid_dy);
          }
          if (Value.length > 1) {
            return {
              is_client_sel: true,
              data: data,
              payload: input,
            };
          } else {
            if (Value !== null || Value !== "") {
              let Verifyinput = {
                userID: Value[0].U_UserID,
                dbConnectionID: Value[0].DBConnectionID,
                clientID: Value[0].C_ClientID,
                from: "SuperAdmin",
              };
            }

            if (Value !== null && Value !== "" && Value !== undefined && Value.length > 0) {
              let Verifyinput = {
                userID: Value[0].U_UserID,
                dbConnectionID: Value[0].DBConnectionID,
                clientID: Value[0].C_ClientID,
                from: "SuperAdmin",
              };

              //This Method to we do a Client Selection for same api
              const resdata: any = await VerifyClientLogin(Verifyinput);
              if (resdata !== "") {
                if (
                  resdata.data !== undefined &&
                  resdata.data &&
                  resdata.data.data &&
                  resdata.data.data &&
                  resdata.data.data.dataList5 &&
                  resdata.data.data.dataList5[0] &&
                  resdata.data.data.dataList5[0][0].IsAdminAccess === false
                ) {
                  return {
                    is_client_sel: false,
                    data: resdata,
                    payload: null,
                    rights: null,
                  };
                } else {
                  localStorage.setItem(
                    "accessToken",
                    CryptoJS.AES.encrypt(
                      resdata.data.data.dataList2 && resdata.data.data.dataList2[0].accessToken,
                      cryptoKey
                    )
                  );
                  localStorage.setItem(
                    "authUser",
                    CryptoJS.AES.encrypt(JSON.stringify(resdata.data.data.dataList1[0]), cryptoKey)
                  );
                  localStorage.setItem(
                    "tokenExpire",
                    resdata.data.data.dataList2 && resdata.data.data.dataList2[0].tokenExpiresIn
                  );

                  let initialRights = ENUMS.side_menu;
                  let newRights = {};

                  Object.keys(initialRights).forEach((key_id) => {
                    if (initialRights[key_id].hasChild) {
                      let newChild: any = [];
                      initialRights[key_id].child.forEach((ch_itm, i) => {
                        // if (ch_itm.hasChild) {
                        let newInnerChild: any = [];

                        if (ch_itm.hasChild) {
                          ch_itm.child.forEach((inner_ch_itm) => {
                            let resDataCheck1 = resdata.data.data.dataList3[0].filter(
                              (itm) => itm.RightName == inner_ch_itm.RightName
                            );

                            if (resDataCheck1.length) {
                              newInnerChild = [
                                ...newInnerChild,
                                {
                                  ...inner_ch_itm,
                                  is_view: resDataCheck1[0].IsView,
                                  is_create: resDataCheck1[0].IsCreate,
                                  is_update: resDataCheck1[0].IsEdit,
                                  is_delete: resDataCheck1[0].IsDelete,
                                  is_export: resDataCheck1[0].IsExport,
                                },
                              ];
                            } else {
                              newInnerChild = [
                                ...newInnerChild,
                                {
                                  ...inner_ch_itm,
                                  is_view: false,
                                  is_create: false,
                                  is_update: false,
                                  is_delete: false,
                                  is_export: false,
                                },
                              ];
                            }
                          });
                        }
                        let resDataCheck2 = resdata.data.data.dataList3[0].filter(
                          (itm) => itm.RightName == ch_itm.RightName
                        );

                        if (ch_itm.hasChild) {
                          newChild = [
                            ...newChild,
                            {
                              ...ch_itm,
                              child: newInnerChild,
                            },
                          ];
                        } else {
                          if (resDataCheck2.length) {
                            newChild = [
                              ...newChild,
                              {
                                ...ch_itm,
                                is_view: resDataCheck2[0].IsView,
                                is_create: resDataCheck2[0].IsCreate,
                                is_update: resDataCheck2[0].IsEdit,
                                is_delete: resDataCheck2[0].IsDelete,
                                is_export: resDataCheck2[0].IsExport,
                              },
                            ];
                          } else {
                            newChild = [
                              ...newChild,
                              {
                                ...ch_itm,
                                is_view: false,
                                is_create: false,
                                is_update: false,
                                is_delete: false,
                                is_export: false,
                              },
                            ];
                          }
                        }
                        newRights = {
                          ...newRights,
                          [key_id]: {
                            ...ENUMS.side_menu[key_id],
                            child: newChild,
                          },
                        };
                      });
                    } else {
                      let resDataCheck3 = resdata.data.data.dataList3[0].filter(
                        (itm) => itm.RightName == initialRights[key_id].RightName
                      );
                      if (resDataCheck3.length) {
                        newRights = {
                          ...newRights,
                          [key_id]: {
                            ...ENUMS.side_menu[key_id],
                            is_view: resDataCheck3.length
                              ? resDataCheck3[0].IsView
                              : initialRights[key_id].is_view,
                            is_create: resDataCheck3.length
                              ? resDataCheck3[0].IsCreate
                              : initialRights[key_id].is_create,
                            is_update: resDataCheck3.length
                              ? resDataCheck3[0].IsEdit
                              : initialRights[key_id].is_update,
                            is_delete: resDataCheck3.length
                              ? resDataCheck3[0].IsDelete
                              : initialRights[key_id].is_delete,
                            is_export: resDataCheck3.length
                              ? resDataCheck3[0].IsExport
                              : initialRights[key_id].is_export,
                          },
                        };
                      } else {
                        if (initialRights[key_id].RightName === "Dashboard") {
                          newRights = {
                            ...newRights,
                            [key_id]: {
                              ...ENUMS.side_menu[key_id],
                              is_view: true,
                              is_create: true,
                              is_update: true,
                              is_delete: true,
                              is_export: true,
                            },
                          };
                        } else {
                          newRights = {
                            ...newRights,
                            [key_id]: {
                              ...ENUMS.side_menu[key_id],
                              is_view: false,
                              is_create: false,
                              is_update: false,
                              is_delete: false,
                              is_export: false,
                            },
                          };
                        }
                      }
                    }
                  });

                  localStorage.setItem("sidemenu_rights", JSON.stringify(newRights));
                  localStorage.setItem(
                    "user_rights",
                    JSON.stringify(resdata.data.data.dataList3[0])
                  );

                  localStorage.setItem(
                    "AppLogo",
                    resdata.data.data.dataList2 && resdata.data.data.dataList2[0].logo
                  );
                  return {
                    is_client_sel: false,
                    data: resdata,
                    payload: input,
                    rights: newRights,
                  };
                }
              } else {
                return false;
              }
            } else {
              return false;
            }
          }
        } else {
          ToasterError("Please Enter Valid Credentials");
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    ToasterError("Token Expired");
    return false;
  }
};

export function getDateTimeInDBFormat(date) {
  if (date !== null && date !== "" && date !== undefined)
    return Moment(date).format("YYYY-MM-DD HH:mm:ss");
  else return "";
}

export function getDateInDBFormatForExcell(date) {
  if (date !== null && date !== "" && date !== undefined) return Moment(date).format("MM-DD-YYYY");
  else return "";
}

export function getDateInDBFormat(date) {
  if (date !== null && date !== "" && date !== undefined) return Moment(date).format("YYYY-MM-DD");
  else return "";
}

export function Mainlogout() {
  sessionStorage.clear();
  localStorage.clear();
  window.location.reload();
  Cookies.remove("SystemSettingDate");
}

// -------------------------------- FILTERING START ----------------------------//

export function HandleFilters(pageName, Pagefilters) {
  localStorage.setItem(pageName, JSON.stringify(Pagefilters));
}

export function HandleRemoveOtherFilters(pageName) {
  ENUMS.filtersPageList.map((itm) => {
    if (pageName !== itm) {
      localStorage.removeItem(itm);
    }
  });
}

export function HandleSetFilterValues(columnData) {
  let getFilterPage: any;
  let getFilterPageName: any;

  ENUMS.filtersPageList.map((itm) => {
    if (localStorage.getItem(itm)) {
      getFilterPageName = itm;
      getFilterPage = JSON.parse(localStorage.getItem(itm));
    }
  });

  if (getFilterPage.activeTab !== undefined && getFilterPage.activeTab !== null) {
    if (window.location.pathname === getFilterPage?.pagePath) {
      // Multi Tab Filter set column wise Logic start :
      handleMultitabFiltersApply(getFilterPage, getFilterPageName, columnData);
    }
  } else {
    if (window.location.pathname === getFilterPage?.pagePath) {
      // Single Tab Filter set column wise Logic start :
      let flag = false;
      getFilterPage?.filters?.forEach((obj, i) => {
        if (Object.keys(obj).indexOf(columnData.id) !== -1) {
          flag = true;
          if (columnData.filterValue !== undefined) {
            getFilterPage.filters[i] = { [columnData.id]: columnData.filterValue };
          } else {
            getFilterPage?.filters.splice(i, 1);
          }
        }
      });
      if (!flag) {
        if (columnData.filterValue !== undefined) {
          getFilterPage?.filters.push({
            [columnData.id]: columnData.filterValue,
          });
        }
      }
      if (getFilterPageName !== undefined && getFilterPage !== undefined) {
        localStorage.setItem(getFilterPageName, JSON.stringify(getFilterPage));
      }
      // Single Tab End
    }
  }
}

// -------------------------------- FILTERING END ----------------------------//

function handleMultitabFiltersApply(activeTabData, getFilterPageName, columnData) {
  let resultObj: any = {};

  Object.keys(activeTabData).forEach((obj) => {
    if (obj !== "activeTab") {
      if (obj !== "pagePath") {
        if (Object.keys(activeTabData[obj]).length) {
          if (activeTabData.activeTab === activeTabData[obj].pageName) {
            resultObj = activeTabData[obj];
          }
        }
      }
    }
  });

  let flag = false;

  resultObj?.filters?.forEach((obj, i) => {
    if (Object.keys(obj).indexOf(columnData.id) !== -1) {
      flag = true;
      if (columnData.filterValue !== undefined) {
        resultObj.filters[i] = { [columnData.id]: columnData.filterValue };
      } else {
        resultObj?.filters.splice(i, 1);
      }
    }
  });
  if (!flag) {
    if (columnData.filterValue !== undefined) {
      resultObj?.filters.push({
        [columnData.id]: columnData.filterValue,
      });
    }
  }

  Object.keys(activeTabData).forEach((obj) => {
    if (obj !== "activeTab") {
      if (obj !== "pagePath") {
        if (Object.keys(activeTabData[obj]).length) {
          if (activeTabData.activeTab === activeTabData[obj].pageName) {
            activeTabData[obj] = resultObj;
          }
        }
      }
    }
  });

  if (getFilterPageName !== undefined && activeTabData !== undefined) {
    localStorage.setItem(getFilterPageName, JSON.stringify(activeTabData));
  }
}

export function HandleMultiTabFilters(pageName, Pagefilters) {
  localStorage.setItem(pageName, JSON.stringify(Pagefilters));
}

export function HandleRemoveOtherMultiTabFilters(pageName) {
  ENUMS.filtersPageList.map((itm) => {
    if (pageName !== itm) {
      localStorage.removeItem(itm);
    }
  });
}

export const handeFloatValueToFixed = (value) => {
  if (!/[a-zA-Z]/.test(value)) {
    // Use a regular expression to validate the format
    const regex = /^\d*\.?\d{0,2}$/;
    if (regex.test(value) || value === "") {
      return value;
    } else {
      let splitedValue: any = value.includes(".") ? value?.split(".")[1] : value;
      if (splitedValue.length > 2) {
        return parseFloat(value).toFixed(2);
      } else {
        return value;
      }
    }
  }
};

export const getGroup_ImportTimeGrid = (startDate, endDate) => {
  let date1: any = startDate;

  let date2: any = endDate;

  let dataResToAppend = {};
  let totalDataResToAppend = {};
  let JsonToImError = {};
  if (date1 !== "" && date2 !== "") {
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    for (let index = 0; index <= diffDays; index++) {
      var result: any = new Date(date1);
      result.setDate(result.getDate() + index);

      let d1: any = date1.getMonth() + 1 + "-" + date1.getDate() + "-" + date1.getFullYear();
      let d2: any = date2.getMonth() + 1 + "-" + date2.getDate() + "-" + date2.getFullYear();
      if (d1 === d2) {
        result.setDate(result.getDate() - index);
      }

      let p = parseInt(result.getMonth()) + 1;
      let q = result.getDate();

      if (p <= 9) {
        p = `0${p}`;
      }
      if (q <= 9) {
        q = `0${q}`;
      }

      totalDataResToAppend = {
        ...totalDataResToAppend,
        [result.getFullYear() + "-" + p + "-" + q]: {
          totalNoOfRooms: 0,
          totalRevenue: 0,
          totalPickups: 0,
          totalNetRooms: 0,
        },
      };
      dataResToAppend = {
        ...dataResToAppend,
        [result.getFullYear() + "-" + p + "-" + q]: {
          1: {
            rateDate: result.getFullYear() + "-" + p + "-" + q,
            roomType: null,
            pepolePerRoom: 0,
            noOfRooms: 0,
            rate: 0,
            actualPickup: 0,
            orderNo: 1,
            netRooms: 0,
          },
        },
      };

      JsonToImError = {
        ...JsonToImError,
        1: {
          pepolePerRoom: "",
          roomType: "",
        },
        // [data_table.OrderNo]: {
        //   roomType: "",
        //   pepolePerRoom: "",
        // },
      };
    }
    return { tabData: dataResToAppend, tabTotal: totalDataResToAppend, tabErr: JsonToImError };
  }
};

// Sort Array by Object Key :
export const getSortDataByObjKey = (key, data, isSort) => {
  isSort === "asc" ? "desc" : "asc";

  const sorted = [...data].sort((a, b) => {
    const valueA = a[key];
    const valueB = b[key];

    if (valueA < valueB) return isSort === "asc" ? -1 : 1;
    if (valueA > valueB) return isSort === "asc" ? 1 : -1;
    return 0;
  });
  return sorted;
};
// Get Selected HTML Function :
export const crmExtensionGetSelectedHtml = () => {
  var range;
  if (document.selection && document.selection.createRange) {
    range = document.selection.createRange();
    return range.htmlText;
  } else if (window.getSelection) {
    var selection = window.getSelection();
    if (selection.rangeCount > 0) {
      range = selection.getRangeAt(0);
      var clonedSelection = range.cloneContents();
      var div = document.createElement("div");
      div.appendChild(clonedSelection);
      return div.innerHTML;
    } else {
      return "";
    }
  } else {
    return "";
  }
};

export const GenerateGUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const isValidGuid = (guid) => {
  if (guid && guid !== "" && guid !== null && guid !== undefined) {
    const guidRegex =
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[4][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
    return guidRegex.test(guid);
  }
};

export const truncateFileName = (filename, maxLength = 10) => {
  if (filename && filename !== "" && filename !== null && filename !== undefined) {
    const [baseName, extension] = filename?.split(".");
    const truncatedBaseName =
      baseName.length > maxLength ? baseName.substring(0, maxLength) + "..." : baseName;

    return truncatedBaseName ? truncatedBaseName : "" + "." + extension ? extension : "";
  }
};
