import moment from "moment";
import React, { useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  ListGroup,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Select, { components } from "react-select";
import BlackCancelIcon from "../Asset/Images/Icons/blackCancelIcon.svg";
import WhiteSaveIcon from "../Asset/Images/Icons/whiteSaveIcon.svg";
import PageLoader from "../Component/Loader/PageLoader";
import {
  CommonService,
  GenerateGUID,
  ToasterError,
  ToasterSuccess,
  fnCheckValidationOfObject,
  isValidGuid,
  truncateFileName,
} from "../Services/CommonServices";
import { getAccountDropdownData } from "../Services/DashboardServices";
import {
  EditAllActivitiesList,
  getContactListing,
  getEmailAssignToDropdownList,
  saveActivities,
} from "../Services/LeftMenuServices";
import { getProjectTermByCategory } from "../Services/Setup&Config/AdditionalSettingService";
import { Cookies } from "react-cookie-consent";
import useMask from "../Component/useMask";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

import DocxIcon from "../Asset/Images/Icons/DocxIcon.svg";
import exporticon from "../Asset/Images/Icons/exporticon.svg";
import ImageIcon from "../Asset/Images/Icons/ImageIcon.svg";
import PdfIcon from "../Asset/Images/Icons/PdfIcon.svg";
import {
  DeletePropertyAttachment,
  PropertyAttachmentList,
  PropertyAttachmentsSave,
} from "../Services/Setup&Config/HotelService";
import Swal from "sweetalert2";

export const AddActivity = (props) => {
  const SelectedPropertyID: any = useSelector((state: any) => state.dashboard.SelectedPropertyID);
  let navigate = useNavigate();
  const globalHotelData: any = useSelector((state: any) => state.dashboard);
  const [loading, setLoading] = React.useState(false);
  const [OnProcessHide, setOnProcessHide] = useState(false);
  const dataSelector: any = useSelector((state: any) => state.session);
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const Token = dataSelector.access_Token;
  let location: any = useLocation();
  const [StatusData, setStatusData]: any = useState([]);
  const [PriorityData, setPriorityData]: any = useState([]);
  const userId: any = dataSelector.auth_UserDetail.U_UserID;
  // Bind Activity All Dropdown Data Field State :
  const [AllDropdown, setAllDropdown]: any = useState([]);
  const [TimeDropdown, setTimeDropdown]: any = useState([]);
  const [hotelChangeLoading, sethotelChangeLoading] = React.useState(false); // Hotel Selection Loader State.

  // AssignTo Dropdown Binding List State :
  const [assingnToEmailResponce, setAssingnToEmailResponce]: any = useState([]);

  // Dropdown Binding of Account List Data State :
  const [accountListData, setaccountListData]: any = useState([]);

  // Dropdown Binding of Contact List Data State :
  const [ContactsList, setContactsList] = useState([]);
  // Save AddActivity Data State :
  const [saveActivity, setSaveActivity]: any = useState({
    activityID: "00000000-0000-0000-0000-000000000000",
    ownerID: null,
    associationID: null,
    associationType_Term: "Accounts",
    accountName: "",
    assignTo: null,
    otherUsers: "",
    activityStatus_Term: "Open",
    activityType_Term: "",
    priority_Term: "Low",
    startDate: new Date(), //getInitialPresentDate(new Date()),
    duration: 0,
    endDate: new Date(), // getInitialPresentDate(today),
    emailReminders_BeforeHrs: 0,
    activitySubject: "",
    activityDetails: "",
    accountID: null, // "00000000-0000-0000-0000-000000000000", //
    isFollowUp: false,
    followUpOnActivityID: null,
    isInComing: false,
    contactID: props.contactID ? props.contactID : null,
    emailSendTo: "",
    hotelID: null,
    strDuration: "",
    activityTime: "",
    reminderSendEmail: false,
    assigntoUserName: "",
    errors: {
      activitySubject: "",
      activityType_Term: "",
      hotelID: "",
      startDate: "",
      accountID: "",

      strDuration: "",
      activityTime: "",
      ValidationRules: [
        {
          FieldName: "activityTime",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
        {
          FieldName: "strDuration",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
        {
          FieldName: "activitySubject",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
        {
          FieldName: "activityType_Term",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
        {
          FieldName: "hotelID",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
        {
          FieldName: "accountID",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
        {
          FieldName: "startDate",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
      ],
    },
  });
  // Selected Hotel Id State :
  const [hotelId, setHotelId] = useState();

  const [files, setFiles]: any = useState([]);
  const [selectedFiles, setSelectedFiles]: any = useState(0);
  const [loadingFiles, setLoadingFiles] = useState(false);

  let optionsHotel: any = [];
  globalHotelData.GlobalHotels !== undefined &&
    globalHotelData.GlobalHotels &&
    globalHotelData.GlobalHotels.length > 0 &&
    globalHotelData.GlobalHotels.map((item: any) => {
      optionsHotel.push({
        label: item.HotelCode + " " + item.PropertyName,
        value: item.PropertyID,
      });
    });
  let optionsType: any = [];
  AllDropdown !== undefined &&
    AllDropdown !== null &&
    AllDropdown.length > 0 &&
    AllDropdown.map((item: any) => {
      if (item.Category === "Activity Type") {
        optionsType.push({
          label: item.Term,
          value: item.TermID,
        });
      }
    });
  //Time Dropdown
  let optionsTime: any = [
    // {
    //   value: null,
    //   label: null,
    // },
  ];

  TimeDropdown !== undefined &&
    TimeDropdown !== null &&
    TimeDropdown.length > 0 &&
    TimeDropdown.map((item: any) => {
      if (item.Category === "Time") {
        optionsTime.push({
          label: item.Term,
          value: item.Term,
        });
      }
    });

  //Duration Dropdown
  let optionsDuration: any = [
    // {
    //   value: null,
    //   label: null,
    // },
  ];
  AllDropdown !== undefined &&
    AllDropdown !== null &&
    AllDropdown.length > 0 &&
    AllDropdown.map((item: any) => {
      if (item.Category === "Duration") {
        optionsDuration.push({
          label: item.Term,
          value: item.Term,
        });
      }
    });
  //Email To Dropdown
  let optionsEmail: any = [
    {
      value: null,
      label: "Select Email To",
    },
  ];
  assingnToEmailResponce !== undefined &&
    assingnToEmailResponce !== null &&
    assingnToEmailResponce.length > 0 &&
    assingnToEmailResponce.map((item: any) => {
      optionsEmail.push({
        label: item.Email,
        value: item.Email,
      });
    });
  //Assign To Dropdown
  let optionsAssignTo: any = [
    // {
    //   value: null,
    //   label: null,
    // },
  ];
  assingnToEmailResponce !== undefined &&
    assingnToEmailResponce !== null &&
    assingnToEmailResponce.length > 0 &&
    assingnToEmailResponce.map((item: any) => {
      optionsAssignTo.push({
        label: item.UserName,
        value: item.UserID,
      });
    });
  //Account To Dropdown
  let optionsAccount: any = [
    // {
    //   value: null,
    //   label: null,
    // },
  ];
  accountListData !== undefined &&
    accountListData !== null &&
    accountListData.length > 0 &&
    accountListData.map((item: any) => {
      optionsAccount.push({
        label: item.AccountName,
        value: item.AccountID,
      });
    });

  //contact To Dropdown
  let optionsContact: any = [];
  ContactsList !== undefined &&
    ContactsList !== null &&
    ContactsList.length > 0 &&
    ContactsList.map((item: any) => {
      optionsContact.push({
        label: item.ContactName,
        value: item.ContactID,
      });
    });

  React.useEffect(() => {
    sethotelChangeLoading(true);
    // Auto fill Email and AssignTO's :
    if (props.EditActivityId === "00000000-0000-0000-0000-000000000000") {
      if (!SelectedPropertyID.includes(",")) {
        getAssignedToOREmailData(SelectedPropertyID);
        getAccountsDropdownsData(SelectedPropertyID);
      }
    }

    // getStatusAndPriorityByProjectTerm();
    // getAllDropdownData();
    // getTimeDropdownData();
    if (
      props.EditActivityId !== undefined &&
      props.EditActivityId !== null &&
      props.EditActivityId !== "00000000-0000-0000-0000-000000000000"
    ) {
      getEditActivitytdetails();
    }

    if (props.type === "RFP Oppo") {
      getHotelAccountDetails();
      // setSaveActivity({
      //   ...saveActivity,
      //   ["hotelID"]: props.hotelID,
      //   ["accountID"]: props.accountID,
      //   ["associationID"]: props.assId,
      //   ["associationType_Term"]: props.type,
      //   ["accountName"]: props.assName,
      // });
    }

    if (props.type === "LNR Oppo") {
      getHotelAccountDetails();
      // setSaveActivity({
      //   ...saveActivity,
      //   ["hotelID"]: props.hotelID,
      //   ["accountID"]: props.accountID,
      //   ["associationID"]: props.assId,
      //   ["associationType_Term"]: props.type,
      //   ["accountName"]: props.assName,
      // });
    }

    if (props.type === "Group Oppo") {
      getHotelAccountDetails();
      // setSaveActivity({
      //   ...saveActivity,
      //   ["hotelID"]: props.hotelID,
      //   ["accountID"]: props.accountID,
      //   ["associationID"]: props.assId,
      //   ["associationType_Term"]: props.type,
      //   ["accountName"]: props.assName,
      // });
    }

    if (props.type === "MAC Oppo") {
      getHotelAccountDetails();
      // setSaveActivity({
      //   ...saveActivity,
      //   ["hotelID"]: props.hotelID,
      //   ["accountID"]: props.accountID,
      //   ["associationID"]: props.assId,
      //   ["associationType_Term"]: props.type,
      //   ["accountName"]: props.assName,
      // });
    }

    if (props.type === "Accounts") {
      getHotelAccountDetails();
      setSaveActivity({
        ...saveActivity,
        ["hotelID"]: props.hotelID,
        ["accountID"]: props.accountID,
        ["associationID"]: props.assId,
        ["associationType_Term"]: props.type,
        ["accountName"]: props.assName,
      });
    }

    if (props.type === "Contact") {
      getHotelAccountDetails();
      setSaveActivity({
        ...saveActivity,
        ["hotelID"]: props.hotelID,
        ["accountID"]: props.accountID,
        ["contactID"]: props.contactID,
        ["associationID"]: props.assId,
        ["associationType_Term"]: props.type,
        ["accountName"]: props.assName,
      });
    }

    if (props.type === "lead") {
      getHotelAccountDetails();
      setSaveActivity({
        ...saveActivity,
        ["hotelID"]: props.hotelID,
        ["accountID"]: props.accountID,
        ["associationID"]: props.assId,
        ["associationType_Term"]: props.type,
        ["accountName"]: props.assName,
      });
    }

    // RFP
    if (location.state && location.state.OppoID) {
      getOpportunityDetail();
      setSaveActivity({
        ...saveActivity,
        ["hotelID"]: props.hotelID,
        ["accountID"]: props.accountID,
        ["associationID"]: props.assId,
        ["associationType_Term"]: props.type,
        ["accountName"]: props.assName,
      });
    }
    // LNR
    if (location.state && location.state.LNROppoID) {
      getLNROpportunityDetail();
      setSaveActivity({
        ...saveActivity,
        ["hotelID"]: props.hotelID,
        ["accountID"]: props.accountID,
        ["associationID"]: props.assId,
        ["associationType_Term"]: props.type,
        ["accountName"]: props.assName,
      });
    }
    // Group
    if (location.state && location.state.GroupOppoID) {
      getGroupOpportunityDetail();
      setSaveActivity({
        ...saveActivity,
        ["hotelID"]: props.hotelID,
        ["accountID"]: props.accountID,
        ["associationID"]: props.assId,
        ["associationType_Term"]: props.type,
        ["accountName"]: props.assName,
      });
    }
    // Meeting & Catering
    if (location.state && location.state.MACOppoID) {
      getMACOpportunityDetail();
      setSaveActivity({
        ...saveActivity,
        ["hotelID"]: props.hotelID,
        ["accountID"]: props.accountID,
        ["associationID"]: props.assId,
        ["associationType_Term"]: props.type,
        ["accountName"]: props.assName,
      });
    }
    getAllDropdownData();
  }, []);

  // Selected Opportunity Account
  async function getHotelAccountDetails() {
    getAssignedToOREmailData(props.hotelID);
    getAccountsDropdownsData(props.hotelID);
    //  Get Contact :
    let input = {
      clientID: clientID,
      propertyIDs: props.hotelID,
      accountID: "",
      searchText: "",
    };

    input.accountID = props.accountID;
    let resContact: any = await getContactListing(input, Token);
    if (resContact.data.success) {
      if (resContact.data !== undefined && resContact.data !== null && resContact.data !== "") {
        if (
          resContact.data.data !== undefined &&
          resContact.data.data !== null &&
          resContact.data.data !== ""
        ) {
          setContactsList(resContact.data.data.dataList1);
        }
      }
    }
  }

  // Selected Opportunity RFP
  async function getOpportunityDetail() {
    getAssignedToOREmailData(props.hotelID);
    getAccountsDropdownsData(props.hotelID);
    //  Get Contact :
    let input = {
      clientID: clientID,
      propertyIDs: props.hotelID,
      accountID: "",
      searchText: "",
    };
    input.accountID = props.accountID;
    let resContact: any = await getContactListing(input, Token);
    if (resContact.data.success) {
      if (resContact.data !== undefined && resContact.data !== null && resContact.data !== "") {
        if (
          resContact.data.data !== undefined &&
          resContact.data.data !== null &&
          resContact.data.data !== ""
        ) {
          setContactsList(resContact.data.data.dataList1);
        }
      }
    }
  }
  // Selected Opportunity LNR
  async function getLNROpportunityDetail() {
    getAssignedToOREmailData(props.hotelID);
    getAccountsDropdownsData(props.hotelID);
    //  Get Contact :
    let input = {
      clientID: clientID,
      propertyIDs: props.hotelID,
      accountID: "",
      searchText: "",
    };
    input.accountID = props.accountID;
    let resContact: any = await getContactListing(input, Token);
    if (resContact.data.success) {
      if (resContact.data !== undefined && resContact.data !== null && resContact.data !== "") {
        if (
          resContact.data.data !== undefined &&
          resContact.data.data !== null &&
          resContact.data.data !== ""
        ) {
          setContactsList(resContact.data.data.dataList1);
        }
      }
    }
  }
  // Selected Opportunity Group
  async function getGroupOpportunityDetail() {
    getAssignedToOREmailData(props.hotelID);
    getAccountsDropdownsData(props.hotelID);
    //  Get Contact :
    let input = {
      clientID: clientID,
      propertyIDs: props.hotelID,
      accountID: "",
      searchText: "",
    };
    input.accountID = props.accountID;
    let resContact: any = await getContactListing(input, Token);
    if (resContact.data.success) {
      if (resContact.data !== undefined && resContact.data !== null && resContact.data !== "") {
        if (
          resContact.data.data !== undefined &&
          resContact.data.data !== null &&
          resContact.data.data !== ""
        ) {
          setContactsList(resContact.data.data.dataList1);
        }
      }
    }
  }

  // Selected Opportunity Meeting & Catering
  async function getMACOpportunityDetail() {
    getAssignedToOREmailData(props.hotelID);
    getAccountsDropdownsData(props.hotelID);
    //  Get Contact :
    let input = {
      clientID: clientID,
      propertyIDs: props.hotelID,
      accountID: "",
      searchText: "",
    };
    input.accountID = props.accountID;
    let resContact: any = await getContactListing(input, Token);
    if (resContact.data.success) {
      if (resContact.data !== undefined && resContact.data !== null && resContact.data !== "") {
        if (
          resContact.data.data !== undefined &&
          resContact.data.data !== null &&
          resContact.data.data !== ""
        ) {
          setContactsList(resContact.data.data.dataList1);
        }
      }
    }
  }

  async function getTimeDropdownData() {
    let input: any = {
      category: "Time",
      clientID: clientID,
    };
    let res: any = await getProjectTermByCategory(input, Token);
    if (res.data.success) {
      if (res.data !== undefined) {
        if (res.data.data !== undefined && res.data.data !== null) {
          setTimeDropdown(res.data.data.dataList1);
        }
      }
    }
  }

  async function getAllDropdownData() {
    let input: any = {
      category: "Activity Type,Duration,Status, Priority,Time",
      clientID: clientID,
    };
    let res: any = await getProjectTermByCategory(input, Token);
    if (res.data.success) {
      if (res.data !== undefined) {
        if (res.data.data !== undefined && res.data.data !== null) {
          let tempStatus =
            res.data.data.dataList1.length > 0 &&
            res.data.data.dataList1.filter((item) => item.Category === "Status");
          setStatusData(tempStatus);
          let tempPriority =
            res.data.data.dataList1.length > 0 &&
            res.data.data.dataList1.filter((item) => item.Category === "Priority");
          setPriorityData(tempPriority);

          let tempTime =
            res.data.data.dataList1.length > 0 &&
            res.data.data.dataList1.filter((item) => item.Category === "Time");

          setTimeDropdown(tempTime);

          setAllDropdown(res.data.data.dataList1);
        }
      }
    }
    sethotelChangeLoading(false);
  }

  function SelectHotel(event) {
    let selectedHotelId: any = event.value;
    setSaveActivity({
      ...saveActivity,
      ["hotelID"]: event.value,
      ["accountID"]: "",
    });
    sethotelChangeLoading(true);
    setHotelId(selectedHotelId);
    if (selectedHotelId !== undefined) {
      getAssignedToOREmailData(selectedHotelId);
    }
    getAccountsDropdownsData(selectedHotelId);
  }

  async function getAccountsDropdownsData(selectedHotelId) {
    let input: any = {
      propertiesIDs: null,
    };
    if (selectedHotelId !== undefined && selectedHotelId !== null && selectedHotelId !== "") {
      input.propertiesIDs = selectedHotelId;

      // if(userTypeID === "78a001cf-27cc-408f-b68f-8bb216cbfef3") {
      //   input.propertiesIDs = null
      // }

      let res: any = await getAccountDropdownData(input, Token);

      if (res.data.success) {
        if (res.data !== undefined && res.data !== null && res.data !== "") {
          if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
            setaccountListData(res.data.data.dataList1);
          }
        }
      }
    } else {
      setaccountListData([]);
      setAssingnToEmailResponce([]);
      setContactsList([]);
    }
    sethotelChangeLoading(false);
  }

  // Hide Hotel / Account & Disabled them conditionally:
  const [handleDisabled, setHandleDisabled] = useState(false);
  const [HandleHide, setHandleHide] = useState(false);

  async function getEditActivitytdetails() {
    let input: any = {
      keyID: props && props.EditActivityId,
    };
    // setLoading(true);
    sethotelChangeLoading(true);
    await fetchActivityFilesList(props.EditActivityId);
    let res: any = await EditAllActivitiesList(input, Token);
    if (res.data.success) {
      if (res.data.statusCode === 200) {
        if (res.data.data !== undefined) {
          if (res.data.data.associationType_Term === "Contact") {
            setHandleDisabled(true);
          } else if (res.data.data.associationType_Term === "RFP Oppo") {
            setHandleDisabled(true);
          } else if (res.data.data.associationType_Term === "LNR Oppo") {
            setHandleDisabled(true);
          } else if (res.data.data.associationType_Term === "Group Oppo") {
            setHandleDisabled(true);
          } else if (res.data.data.associationType_Term === "MAC Oppo") {
            setHandleDisabled(true);
          } else if (res.data.data.associationType_Term === "Lead") {
            setHandleHide(true);
          } else {
            setHandleDisabled(false);
          }

          setSaveActivity({
            ...saveActivity,
            ["activityID"]: res.data.data.activityID,
            ["ownerID"]: res.data.data.ownerID,
            ["associationID"]: res.data.data.associationID,
            ["associationType_Term"]: res.data.data.associationType_Term,
            ["assignTo"]: res.data.data.assignTo,
            ["otherUsers"]: res.data.data.otherUsers,
            ["activityStatus_Term"]: res.data.data.activityStatus_Term,
            ["activityType_Term"]: res.data.data.activityType_Term,
            ["priority_Term"]: res.data.data.priority_Term,
            ["startDate"]: new Date(res.data.data.startDate),
            ["duration"]: res.data.data.duration,
            ["endDate"]:
              res.data.data.endDate === null ? new Date() : new Date(res.data.data.endDate),
            ["emailReminders_BeforeHrs"]: res.data.data.emailReminders_BeforeHrs,
            ["activitySubject"]: res.data.data.activitySubject,
            ["activityDetails"]: res.data.data.activityDetails ? res.data.data.activityDetails : "",
            ["accountID"]: res.data.data.accountID
              ? res.data.data.accountID
              : "00000000-0000-0000-0000-000000000000",
            ["isFollowUp"]: res.data.data.isFollowUp,
            ["followUpOnActivityID"]: res.data.data.followUpOnActivityID,
            ["isInComing"]: res.data.data.isInComing,
            ["contactID"]: res.data.data.contactID,
            ["emailSendTo"]: res.data.data.emailSendTo,
            ["hotelID"]: res.data.data.hotelID,
            ["strDuration"]: res.data.data.strDuration,
            ["activityTime"]: res.data.data.activityTime,
            ["reminderSendEmail"]: res.data.data.reminderSendEmail,
            ["assigntoUserName"]: res.data.data.assigntoUserName,
            ["accountName"]: res.data.data.accountName,
          });
          // saveActivity({ IsData: false, data: res.data.data.dataList1 });
        }
        getAssignedToOREmailData(res.data.data.hotelID);
        getContactDropdownEditTime(res.data.data.hotelID, res.data.data.accountID);
        getAccountsDropdownsData(res.data.data.hotelID);
        // SelectHotel(res.data.data.hotelID);
      }
    }
    // setLoading(false);
    sethotelChangeLoading(false);
  }

  async function getAssignedToOREmailData(selectedHotelId) {
    if (selectedHotelId) {
      let input = {
        clientID: clientID,
        propertyIDs: selectedHotelId,
      };

      // if (userTypeID === "78a001cf-27cc-408f-b68f-8bb216cbfef3") {
      //   input.propertyIDs = null;
      // }
      //this Function to get assignTo Dropdown From same api

      const res: any = await getEmailAssignToDropdownList(input, Token);
      if (res.data.success) {
        if (res.data !== undefined && res.data !== null && res.data !== "") {
          if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
            let userIDRes = res.data.data.dataList1.filter((item) => item.UserID === userId);
            setAssingnToEmailResponce(res.data.data.dataList1);

            // Auto fill Email and AssignTO's :

            if (props.EditActivityId === "00000000-0000-0000-0000-000000000000") {
              // setSaveActivity({
              //   ...saveActivity,
              //   ["hotelID"]: selectedHotelId,
              //   ["assigntoUserName"]: userIDRes.length && userIDRes[0].UserName,
              //   ["emailSendTo"]: userIDRes.length && userIDRes[0].Email,
              // });

              setSaveActivity({
                ...saveActivity,
                ["hotelID"]: selectedHotelId,
                ["assigntoUserName"]: userIDRes.length && userIDRes[0].UserName,
                ["assignTo"]: userIDRes.length && userIDRes[0].UserID,

                // ["emailSendTo"]: userIDRes.length && userIDRes[0].Email,

                ["accountID"]: props.accountID,
                ["associationID"]: props.assId,
                ["associationType_Term"]: props.type,
                ["accountName"]: props.assName,
              });
            }
          }
        }
      }
    }
  }

  async function getContactDropdownEditTime(hotelsID, accountsID) {
    let input = {
      clientID: clientID,
      propertyIDs: hotelsID,
      accountID: accountsID,
      searchText: "",
    };

    let res: any = await getContactListing(input, Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null && res.data !== "") {
        if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
          setContactsList(res.data.data.dataList1);
        }
      }
    }
  }
  async function SelectedAccountId(event) {
    let accountID = event.value;
    if (props.Activity === "MainActivity") {
      // getHotelAccountDetails();
      setSaveActivity({
        ...saveActivity,
        ["associationID"]: accountID,
        ["associationType_Term"]: "Accounts",
        ["accountID"]: accountID,
        ["accountName"]: event.label,
      });
    } else {
      setSaveActivity({
        ...saveActivity,
        ["associationID"]: accountID,
        ["accountID"]: accountID,
        ["accountName"]: event.label,
      });
    }
    let input = {
      clientID: clientID,
      propertyIDs: hotelId,
      accountID: "",
      searchText: "",
    };
    input.accountID = accountID;
    let res: any = await getContactListing(input, Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null && res.data !== "") {
        if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
          setContactsList(res.data.data.dataList1);
        }
      }
    }
  }
  function SelectedDuration(event) {
    let selectedDuration: any = event.value;
    let hm = selectedDuration;
    let a = hm.split(":");
    let minutes = parseInt(a[0]) * 60 + parseInt(a[1]);
    setSaveActivity({
      ...saveActivity,
      ["duration"]: minutes,
      ["strDuration"]: selectedDuration,
    });
  }

  async function SaveActivity() {
    setSaveActivity({
      ...saveActivity,
      startDate: saveActivity.startDate && moment(saveActivity.endDate).format("YYYY-MM-DD"),
      endDate: saveActivity.endDate && moment(saveActivity.endDate).format("YYYY-MM-DD"),
    });

    let obj = fnCheckValidationOfObject(saveActivity);

    setSaveActivity({
      ...obj.obj,
    });
    if (obj.isValid) {
      setOnProcessHide(true);

      let res: any = await saveActivities(saveActivity, Token);
      if (res.data !== undefined) {
        let Message = "Activity saved SuccessFully";
        let MessageUpdate = "Activity updated SuccessFully";
        if (res.data.statusCode === 200) {
          if (typeof props.getEditActivitytdetails !== "string") {
            props.getEditActivitytdetails();
          }
          if (typeof props.setActivityGetCount !== "undefined" && props.setActivityGetCount) {
            props.setActivityGetCount(props.activityGetCount + 1);
          }
          if (
            props.EditActivityId !== undefined &&
            props.EditActivityId !== null &&
            props.EditActivityId !== "00000000-0000-0000-0000-000000000000"
          ) {
            // Save Documents :
            if (files.length > 0) {
              await handleUploadFiles(res.data.data.activityID);
              setSelectedFiles(0);
            }
            ToasterSuccess(MessageUpdate);
          } else {
            // Save Documents :
            if (files.length > 0) {
              await handleUploadFiles(res.data.data.activityID);
              setSelectedFiles(0);
            }
            ToasterSuccess(Message);
          }
          if (!props.handleNavigateToDetails) {
            if (
              props.EditActivityId === undefined ||
              props.EditActivityId === null ||
              props.EditActivityId === "00000000-0000-0000-0000-000000000000"
            ) {
              navigate("/activity/details", { state: { activityID: res.data.data.activityID } });
            }
          }
          props.onHide(false);
        } else {
          ToasterError("Some thing went wrong.");
        }
      } else {
        ToasterError(res.message);
      }
      setOnProcessHide(false);
    }
  }

  async function SaveActivityFollowUp() {
    let obj = fnCheckValidationOfObject(saveActivity);
    setSaveActivity({
      ...obj.obj,
    });
    if (obj.isValid) {
      setOnProcessHide(true);
      let res: any = await saveActivities(saveActivity, Token);

      if (res.data !== undefined) {
        let Message = "Activity saved SuccessFully";
        let MessageUpdate = "Activity updated SuccessFully";
        if (res.data.statusCode === 200) {
          if (typeof props.getEditActivitytdetails !== "string") {
            props.getEditActivitytdetails();
          }
          if (typeof props.setActivityGetCount !== "undefined" && props.setActivityGetCount) {
            props.setActivityGetCount(props.activityGetCount + 1);
          }

          if (saveActivity.activityID !== "00000000-0000-0000-0000-000000000000") {
            // Save Documents :
            if (files.length > 0) {
              await handleUploadFiles(res.data.data.activityID);
              setSelectedFiles(0);
            }
            setSaveActivity({
              ...saveActivity,
              ["activityID"]: "00000000-0000-0000-0000-000000000000",
              ["activitySubject"]: "",
              ["activityType_Term"]: "",
              ["startDate"]: "",
              ["activityStatus_Term"]: "Open",
              ["endDate"]: "",
              ["activityTime"]: "",
              ["duration"]: "",
              ["strDuration"]: "",
              ["assigntoUserName"]: "",
              ["contactID"]: "",
              ["emailSendTo"]: "",
            });
            ToasterSuccess(MessageUpdate);
          } else {
            // Save Documents :
            if (files.length > 0) {
              await handleUploadFiles(res.data.data.activityID);
              setSelectedFiles(0);
            }
            setSaveActivity({
              ...saveActivity,
              ["activityID"]: "00000000-0000-0000-0000-000000000000",
              ["activitySubject"]: "",
              ["activityType_Term"]: "",
              ["startDate"]: "",
              ["activityStatus_Term"]: "Open",
              ["endDate"]: "",
              ["activityTime"]: "",
              ["duration"]: "",
              ["strDuration"]: "",
              ["assigntoUserName"]: "",
              ["contactID"]: "",
              ["emailSendTo"]: "",
            });
            ToasterSuccess(Message);
          }

          // props.onHide(false);
        } else {
          ToasterError("Some thing went wrong.");
        }
      } else {
        ToasterError(res.message);
      }
      setOnProcessHide(false);
    }
  }

  // File Upload Section : ---------------------- Start

  const fetchActivityFilesList = async (ActivityId: string) => {
    let data = {
      clientID: clientID,
      associationIDs: ActivityId,
      associationType_Term: "Activity/Files",
    };

    let res: any = await PropertyAttachmentList(data, Token);
    if (res.status === 200) {
      if (res.data.success) {
        if (res.data !== undefined && res.data !== null) {
          if (res.data.data !== undefined && res.data.data !== null) {
            let fileUrls: any = [];
            res.data?.data?.dataList1?.forEach((file_el: any, idx: any) => {
              // let fileURL = "";
              // fileURL = URL.createObjectURL(file_el);
              fileUrls.push({
                id: file_el.AttachmentID, // GenerateGUID(),
                isSaved: true,
                url: file_el.DocumentPath,
                name: file_el.DocumentName,
                fileObj: "",
              });
            });
            setFiles([...files, ...fileUrls]);
          }
        }
      }
    }
  };

  const handleUploadFiles = async (activityId) => {
    var formData = new FormData();
    formData.append("OwnerType", "");
    formData.append("AssociationID", activityId);
    formData.append("AssociationType_Term", "Activity/Files");
    formData.append("AboutDocument", "");
    formData.append("ExpiryDate", "");
    formData.append("RefAttachmentID", "");
    formData.append("DocumentFrom", "Activity/Files");
    files?.forEach((form_fl) => {
      formData.append("FormFiles", form_fl.fileObj);
    });

    let res: any = await PropertyAttachmentsSave(formData, Token);
    if (res.status === 200) {
      if (res.data.statusCode === 200) {
        setFiles([]);
      } else {
        ToasterError("Something Went Wrong");
      }
    } else {
    }
  };

  const handleAttachmentFile = (file_res) => {
    let fileUrls: any = [];

    file_res.forEach((file_el: any) => {
      let fileURL = "";
      fileURL = URL.createObjectURL(file_el);
      fileUrls.push({
        id: GenerateGUID(),
        isSaved: false,
        url: fileURL,
        name: file_el.name,
        fileObj: file_el,
      });
    });

    setSelectedFiles(fileUrls.length);
    let tmpFiles = [...files, ...fileUrls];

    // Check All files sizes :
    let totalSize = 0;
    tmpFiles.forEach((curr_Obj: any) => {
      if (curr_Obj?.fileObj !== "") {
        totalSize = totalSize + parseFloat((curr_Obj?.fileObj?.size / 1000000).toFixed(2));
      } else {
        totalSize = totalSize + 0;
      }
    });
    // console.log("totalSize", totalSize);
    if (totalSize >= 25) {
      setSelectedFiles(0);
      ToasterError("Total files size should be less than 25MB.");
      return;
    }
    setFiles([...files, ...fileUrls]);
  };

  // const handleDelete_UnSavedFiles = async (file_Id: any) => {
  //   let tmpFiles = [...files];
  //   let newLocalFiles = tmpFiles.filter((itm) => itm.id !== file_Id);
  //   setFiles(newLocalFiles);
  // };

  // const handleDelete_SavedFiles = async (att_id: any) => {
  //   let input = {
  //     keyID: att_id,
  //   };
  //   let res: any = await DeletePropertyAttachment(input, Token);
  //   if (res.data.success) {
  //     let tmpFiles = [...files];
  //     let newLocalFiles = tmpFiles.filter((itm) => itm.id !== att_id);
  //     setFiles(newLocalFiles);
  //   }
  // };

  const handleDelete_UnSavedFiles = async (file_Id: any) => {
    Swal.fire({
      title: "Are you sure want to delete ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let tmpFiles = [...files];
        let newLocalFiles = tmpFiles.filter((itm) => itm.id !== file_Id);
        setFiles(newLocalFiles);
      }
    });
  };

  const handleDelete_SavedFiles = async (att_id: any) => {
    Swal.fire({
      title: "Are you sure want to delete ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let input = {
          keyID: att_id,
        };
        let res: any = await DeletePropertyAttachment(input, Token);
        if (res.data.success) {
          let tmpFiles = [...files];
          let newLocalFiles = tmpFiles.filter((itm) => itm.id !== att_id);
          setFiles(newLocalFiles);
        }
      }
    });
  };

  // File Upload Section : ---------------------- End

  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-50w"
        // className="quickAdd-popup"
        className={`quickAdd-popup`}
        scrollable={true}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Activity Information</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {!loading ? (
            <>
              <Row>
                <Col xs={6} className="mb-3">
                  <FloatingLabel controlId="floatingInput" label="Objective">
                    <Form.Control
                      type="text"
                      placeholder="Objective"
                      maxLength={1066}
                      value={saveActivity.activitySubject}
                      onChange={(e) =>
                        setSaveActivity({
                          ...saveActivity,
                          ["activitySubject"]: e.target.value,
                        })
                      }
                      className={`form-control ${
                        saveActivity.errors.activitySubject && "is-invalid"
                      }`}
                    />
                    {saveActivity.errors.activitySubject && (
                      <Form.Control.Feedback type="invalid">
                        {saveActivity.errors.activitySubject}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Col>
                <Col xs={6} className="mb-3">
                  <div
                    className={`${
                      saveActivity.errors.activityType_Term.length && "red-border-masking"
                    }`}
                  >
                    <FloatingLabel controlId="floatingSelectGrid" label="">
                      <Select
                        placeholder="Select Type"
                        options={optionsType}
                        value={optionsType.filter(
                          (obj) =>
                            saveActivity.activityType_Term &&
                            saveActivity.activityType_Term.includes(obj.label)
                        )}
                        onChange={(e: any) =>
                          setSaveActivity({
                            ...saveActivity,
                            ["activityType_Term"]: e.label,
                          })
                        }
                      />
                    </FloatingLabel>
                  </div>
                  <small style={{ color: "#dc3545" }}>
                    {" "}
                    {saveActivity.errors.activityType_Term &&
                      saveActivity.errors.activityType_Term}{" "}
                  </small>
                </Col>
              </Row>
              <Row>
                <Col xs={6} className="mb-3">
                  {/* <FloatingLabel controlId="floatingInput" label="Start Date">
                    <Form.Control
                      type="date"
                      placeholder="mm-dd-yyyy"
                      value={saveActivity.startDate}
                      // max={saveActivity.endDate}
                      onChange={(e) =>
                        setSaveActivity({
                          ...saveActivity,
                          ["startDate"]: e.target.value,
                          ["endDate"]: e.target.value,
                        })
                      }
                      className={`form-control ${saveActivity.errors.startDate && "is-invalid"}`}
                    />
                    {saveActivity.errors.startDate && (
                      <Form.Control.Feedback type="invalid">
                        {saveActivity.errors.startDate}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel> */}
                  <div className="DatePicker-col">
                    <FloatingLabel controlId="floatingInput" label="Start Date">
                      <DatePicker
                        selected={saveActivity.startDate && saveActivity.startDate}
                        className={`form-control ${saveActivity.errors.startDate && "is-invalid"}`}
                        onChange={(e) => {
                          setSaveActivity({
                            ...saveActivity,
                            ["startDate"]: e,
                            ["endDate"]: e,
                          });
                        }}
                        // minDate={new Date("1753/01/01")}
                        minDate={new Date("2010/01/01")}
                        maxDate={new Date("9999/12/31")}
                        // minDate={
                        //   SaveMeetingCatering.endDate !== null && SaveMeetingCatering.endDate > SaveMeetingCatering.startDate ? SaveMeetingCatering.startDate : new Date()
                        // }
                        dateFormat={Cookies.get("SystemSettingDate")}
                        placeholder={Cookies.get("SystemSettingDate")}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                      {saveActivity.errors.startDate && (
                        <Form.Control.Feedback type="invalid">
                          {saveActivity.errors.startDate}
                        </Form.Control.Feedback>
                      )}
                    </FloatingLabel>
                  </div>
                </Col>
                <Col xs={6} className="mb-3">
                  <div className="DatePicker-col">
                    <FloatingLabel controlId="floatingInput" label="Due Date">
                      <DatePicker
                        className="form-control"
                        selected={saveActivity.endDate && saveActivity.endDate}
                        minDate={saveActivity.startDate}
                        maxDate={new Date("9999/12/31")}
                        // value={saveActivity.endDate}
                        onChange={(e) => {
                          setSaveActivity({
                            ...saveActivity,
                            ["endDate"]: e,
                          });
                        }}
                        dateFormat={Cookies.get("SystemSettingDate")}
                        placeholder={Cookies.get("SystemSettingDate")}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </FloatingLabel>
                  </div>
                  {/* <FloatingLabel controlId="floatingInput" label="Due Date">
                    <Form.Control
                      type="date"
                      placeholder="mm-dd-yyyy"
                      min={saveActivity.startDate}
                      value={saveActivity.endDate}
                      onChange={(e) =>
                        setSaveActivity({
                          ...saveActivity,
                          ["endDate"]: e.target.value,
                        })
                      }
                    />
                  </FloatingLabel> */}
                </Col>
              </Row>
              {/* Time & Duration */}
              <Row>
                <Col xs={6} className="mb-3">
                  <div className={`${saveActivity.errors.activityTime && "red-border-masking"}`}>
                    <FloatingLabel controlId="floatingInput" label="">
                      <Select
                        // format="##-##-##"
                        placeholder="Select Time"
                        options={optionsTime}
                        value={optionsTime.filter(
                          (obj) =>
                            saveActivity.activityTime &&
                            saveActivity.activityTime.includes(obj.label)
                        )}
                        onChange={(e: any) =>
                          setSaveActivity({
                            ...saveActivity,
                            ["activityTime"]: e.value,
                          })
                        }
                      />
                    </FloatingLabel>
                  </div>
                  <small style={{ color: "#dc3545" }}>
                    {" "}
                    {saveActivity.errors.activityTime && saveActivity.errors.activityTime}{" "}
                  </small>
                </Col>
                <Col xs={6} className="mb-3">
                  <div className={`${saveActivity.errors.strDuration && "red-border-masking"}`}>
                    <FloatingLabel controlId="floatingInput" label="">
                      <Select
                        placeholder="Select Duration"
                        value={optionsDuration.filter(
                          (obj) =>
                            saveActivity.strDuration && saveActivity.strDuration.includes(obj.label)
                        )}
                        // className={`form-control ${saveActivity.errors.activityTime && "is-invalid"}`}
                        options={optionsDuration}
                        // isMulti
                        onChange={SelectedDuration}
                      />
                    </FloatingLabel>
                  </div>
                  <small style={{ color: "#dc3545" }}>
                    {" "}
                    {saveActivity.errors.strDuration && saveActivity.errors.strDuration}{" "}
                  </small>
                </Col>
              </Row>
              {/* Hotel & Account Dropdown . */}
              {props.Activity ? (
                !HandleHide && (
                  <Row>
                    <Col xs={6} className="mb-3">
                      <div
                        className={`${saveActivity.errors.hotelID.length && "red-border-masking"}`}
                      >
                        <FloatingLabel controlId="floatingSelectGrid" label="">
                          <Select
                            placeholder="Select Hotels"
                            value={optionsHotel.filter(
                              (obj) =>
                                saveActivity.hotelID && saveActivity.hotelID.includes(obj.value)
                            )}
                            options={optionsHotel}
                            // isMulti
                            onChange={SelectHotel}
                            isDisabled={handleDisabled ? true : false}
                          />
                        </FloatingLabel>
                      </div>
                      <small style={{ color: "#dc3545" }}>
                        {" "}
                        {saveActivity.errors.hotelID && saveActivity.errors.hotelID}{" "}
                      </small>
                    </Col>
                    <Col xs={6} className="mb-3">
                      <div
                        className={`${
                          saveActivity.errors.accountID.length && "red-border-masking"
                        }`}
                      >
                        <FloatingLabel controlId="floatingSelectGrid" label="">
                          <Select
                            placeholder="Select Account"
                            value={optionsAccount.filter(
                              (obj) =>
                                saveActivity.accountID && saveActivity.accountID.includes(obj.value)
                            )}
                            // className={`form-control ${saveActivity.errors.activityTime && "is-invalid"}`}
                            options={optionsAccount}
                            // isMulti
                            onChange={SelectedAccountId}
                            isDisabled={handleDisabled ? true : false}
                          />
                          {saveActivity.errors.accountID && (
                            <Form.Control.Feedback type="invalid">
                              {saveActivity.errors.accountID}
                            </Form.Control.Feedback>
                          )}
                        </FloatingLabel>
                      </div>
                      <small style={{ color: "#dc3545" }}>
                        {" "}
                        {saveActivity.errors.accountID && saveActivity.errors.accountID}{" "}
                      </small>
                    </Col>
                  </Row>
                )
              ) : (
                <></>
              )}

              <Row>
                <Col xs={6} className="mb-3">
                  <FloatingLabel controlId="floatingSelectGrid" label="">
                    <Select
                      placeholder="Select Assigned To"
                      value={optionsAssignTo.filter(
                        (obj) =>
                          saveActivity.assigntoUserName &&
                          saveActivity.assigntoUserName.includes(obj.label)
                      )}
                      // value={optionsAssignTo.filter((obj) => saveActivity.assignTo.includes(obj.value))}
                      // className={`form-control ${saveActivity.errors.activityTime && "is-invalid"}`}
                      options={optionsAssignTo}
                      onChange={(e: any) => {
                        let filteredValue = assingnToEmailResponce.filter(
                          (obj) => e.label === obj.UserName
                        );
                        setSaveActivity({
                          ...saveActivity,
                          ["assignTo"]: e.value,
                          ["assigntoUserName"]: e.label,
                          // ["emailSendTo"]:
                          //   filteredValue && filteredValue.length > 0 && filteredValue[0].Email,
                        });
                      }}
                    />
                  </FloatingLabel>
                </Col>

                {props.type !== "lead" && (
                  <Col xs={6} className="mb-3">
                    <FloatingLabel controlId="floatingSelectGrid" label="">
                      <Select
                        placeholder="Select Contact"
                        // className={`form-control ${saveActivity.errors.activityTime && "is-invalid"}`}
                        options={optionsContact}
                        // isMulti
                        value={optionsContact.filter(
                          (obj) =>
                            saveActivity.contactID && saveActivity.contactID.includes(obj.value)
                        )}
                        onChange={(e: any) =>
                          setSaveActivity({
                            ...saveActivity,
                            ["contactID"]: e.value,
                          })
                        }
                      />
                    </FloatingLabel>
                  </Col>
                )}
              </Row>
              <Row>
                <Col xs={6} className="mb-3">
                  <FloatingLabel controlId="floatingSelectGrid" label="">
                    <Select
                      placeholder="Select Email To"
                      // className={`form-control ${saveActivity.errors.activityTime && "is-invalid"}`}
                      options={optionsEmail}
                      // isMulti
                      value={optionsEmail.filter(
                        (obj) =>
                          saveActivity.emailSendTo && saveActivity.emailSendTo.includes(obj.value)
                      )}
                      onChange={(e: any) =>
                        setSaveActivity({
                          ...saveActivity,
                          ["emailSendTo"]: e.value,
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>
                <Col xs={6} className="mb-3">
                  {/* <FloatingLabel controlId="floatingInput" label="Results">
                    <Form.Control
                      as="textarea"
                      placeholder="Results"
                      style={{ height: '100px' }}
                      value={saveActivity.activityDetails}
                      onChange={(e) =>
                        setSaveActivity({
                          ...saveActivity,
                          ["activityDetails"]: e.target.value,
                        })
                      }
                    />
                  </FloatingLabel> */}
                </Col>
              </Row>
              <Row>
                <Col xs={6} className="mb-3">
                  <label className="for-radio-header" htmlFor="Status">
                    Status
                  </label>
                  {["radio"].map((type: any) => (
                    <div key={`inline-${type}`} className="option-field">
                      {StatusData.length > 0 &&
                        StatusData.map((item) => (
                          <>
                            <Form.Check
                              inline
                              label={item.TermCode}
                              name={item.TermCode}
                              value={item.TermCode}
                              type={type}
                              id={item.TermCode}
                              checked={
                                saveActivity.activityStatus_Term === item.TermCode ? true : false
                              }
                              onChange={() =>
                                setSaveActivity({
                                  ...saveActivity,
                                  ["activityStatus_Term"]: item.TermCode,
                                })
                              }
                            />
                          </>
                        ))}

                      {/* <Form.Check
                      inline
                      label="Complete"
                      name="group1"
                      value="Complete"
                      // defaultChecked={saveActivity.activityStatus_Term === "Complete" ? true : false}
                      checked={saveActivity.activityStatus_Term === "Complete" ? true : false}
                      type={type}
                      id="Not Viable"
                      onChange={(e) =>
                        setSaveActivity({
                          ...saveActivity,
                          ["activityStatus_Term"]: e.target.value,
                        })
                      }
                    /> */}
                    </div>
                  ))}
                </Col>
                <Col xs={6} className="mb-3">
                  <label className="for-radio-header" htmlFor="Priority">
                    Priority
                  </label>
                  {["radio"].map((type: any) => (
                    <div key={`inline-${type}`} className="option-field">
                      {PriorityData.length > 0 &&
                        PriorityData.map((item) => (
                          <>
                            {" "}
                            <Form.Check
                              inline
                              label={item.TermCode}
                              name={item.TermCode}
                              value={item.TermCode}
                              type={type}
                              checked={saveActivity.priority_Term === item.TermCode ? true : false}
                              id={item.TermCode}
                              onChange={() =>
                                setSaveActivity({
                                  ...saveActivity,
                                  ["priority_Term"]: item.TermCode,
                                })
                              }
                            />
                          </>
                        ))}
                    </div>
                  ))}
                </Col>
              </Row>

              <Row>
                <Col xs={12} className="mb-3">
                  {/* <FloatingLabel controlId="floatingInput" label="Results">
                    <Form.Control
                      as="textarea"
                      placeholder="Results"
                      style={{ height: "100px" }}
                      value={saveActivity.activityDetails}
                      onChange={(e) =>
                        setSaveActivity({
                          ...saveActivity,
                          ["activityDetails"]: e.target.value,
                        })
                      }
                    />
                  </FloatingLabel> */}

                  {/* <div className="col-md-12"> */}
                  {/* <div className={`${EmailTemplate.errors.emailBody && "red-border"}`}> */}
                  <CKEditor
                    editor={Editor}
                    data={saveActivity.activityDetails}
                    config={{
                      extraPlugins: [CommonService.uploadPlugin],
                    }}
                    onBlur={(event, editor) => {
                      const data = editor.getData();
                      setSaveActivity({
                        ...saveActivity,
                        ["activityDetails"]: data,
                      });
                    }}
                  />
                  {/* </div>
              {EmailTemplate.errors.emailBody && (
                <>
                  {" "}
                  <span className="feedbackss">This field is required.</span>
                </>
              )}
            </div> */}
                </Col>
              </Row>

              {/* File Upload Section */}
              <Row>
                <Col xs={12} className="mb-3">
                  <div className="transparent-inputs">
                    <div>
                      <Row>
                        {/* Left Section: File Upload */}
                        <Col
                          xs={12}
                          md={12}
                          className="d-flex flex-column align-items-center p-3 m-2"
                          style={{ border: "1px solid #ccced1", width: "-webkit-fill-available" }}
                        >
                          <h6 className="text-center text-lg mb-3 mt-3" htmlFor="Image">
                            {/* {files.length ? `Selected Files: (${files.length})` : "Select Files"} */}
                            {selectedFiles !== 0
                              ? `Selected Files: (${selectedFiles})`
                              : "Select Files"}
                          </h6>

                          <div className="file-upload-container">
                            {/* Upload Icon */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon icon-tabler icon-tabler-upload"
                              width="30"
                              height="30"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="#000000"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                              <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                              <path d="M7 9l5 -5l5 5" />
                              <path d="M12 4v12" />
                            </svg>

                            {/* Label to Trigger File Input */}
                            <label
                              htmlFor="dropzone-files"
                              className="custom-file-label mt-2 ms-2 cursor-pointer"
                            >
                              Choose Files
                            </label>

                            {/* Hidden File Input */}
                            <input
                              id="dropzone-files"
                              type="file"
                              className="upload-file-field d-none"
                              multiple={true}
                              onChange={(e) => handleAttachmentFile(Array.from(e.target.files))}
                            />
                          </div>
                        </Col>

                        {/* Right Section: File List Display */}
                        <Col xs={12} md={12} className="mt-3">
                          <Table responsive striped bordered hover>
                            <thead>
                              <tr>
                                <th>File Name</th>
                                <th>
                                  <div className="d-flex justify-content-end me-5">Action</div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {files.length > 0 ? (
                                files.map((fileEle, index) => (
                                  <tr key={index}>
                                    <td>
                                      <span>{truncateFileName(fileEle.name, 60)}</span>
                                    </td>
                                    <td>
                                      <div className="d-flex justify-content-end me-5">
                                        <button
                                          className="cursor-pointer text-primary me-3"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            window.open(fileEle.url, "_blank");
                                          }}
                                        >
                                          <i className="fa fa-eye"></i>
                                        </button>
                                        <button
                                          className="cursor-pointer text-danger"
                                          onClick={(e) => {
                                            e.stopPropagation();

                                            if (fileEle?.isSaved) {
                                              handleDelete_SavedFiles(fileEle.id);
                                            } else {
                                              handleDelete_UnSavedFiles(fileEle.id);
                                            }
                                          }}
                                        >
                                          <b>✕</b>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr className="text-center">
                                  <td colSpan="2" className="text-muted">
                                    No files selected.
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <PageLoader />
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            <span className="me-2">
              <img src={BlackCancelIcon} alt="" />
            </span>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={SaveActivity}
            disabled={OnProcessHide === true ? true : false}
          >
            <span className="me-2">
              <img src={WhiteSaveIcon} alt="" />
            </span>
            Save
          </Button>
          <Button
            variant="primary"
            onClick={SaveActivityFollowUp}
            disabled={OnProcessHide === true ? true : false}
          >
            <span className="me-2">
              <img src={WhiteSaveIcon} alt="" />
            </span>
            Save and Enter Follow-Up Objective
          </Button>
          {/* {
            props.EditActivityId !== undefined &&
            props.EditActivityId !== null &&
            props.EditActivityId !== "00000000-0000-0000-0000-000000000000" &&
            <Button variant="primary copyIcon">
              <span className="me-2">
                <img src={WhiteCopyIcon} alt="" />
              </span>
              copy
            </Button>
          } */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddActivity;
