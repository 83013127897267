import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { HandleFilters, HandleRemoveOtherFilters } from "../../Services/CommonServices";

export default function ReportNavigations() {
  const navigate = useNavigate();
  useEffect(() => {
    // if (!JSON.parse(localStorage.getItem("reportFilter"))) {
    //   HandleRemoveOtherFilters("reportFilter");
    //   HandleFilters("reportFilter", {
    //     pagePath: "/DashboardWeeklyReport",
    //     pageName: "report",
    //     filters: [],
    //     isSort: [],
    //   });
    // }
  }, []);

  return (
    <div className="nav-pills">
      <ul className="p-0 mb-0" style={{ overflow: "auto", height: "72vh",  }}>
        <li
          className={`nav-link ${location.pathname === "/DashboardWeeklyReport" ? "active" : ""}`}
          onClick={(e) => navigate("/DashboardWeeklyReport")}
        >
          Dashboard Weekly Report
        </li>

        <li
          className={`nav-link ${location.pathname === "/WeeklyReport" ? "active" : ""}`}
          onClick={() => navigate("/WeeklyReport")}
        >
          Weekly Report
        </li>
        <li
          className={`nav-link ${
            location.pathname === "/multiple-property-weekly-report" ? "active" : ""
          }`}
          onClick={() => navigate("/multiple-property-weekly-report")}
        >
          Multiple Property Weekly Report
        </li>
        <li
          className={`nav-link ${location.pathname === "/MarketingPlanReport" ? "active" : ""}`}
          onClick={() => navigate("/MarketingPlanReport")}
        >
          Marketing Plan Report
        </li>

        <li
          className={`nav-link ${location.pathname === "/MonthlyReport" ? "active" : ""}`}
          onClick={() => navigate("/MonthlyReport")}
        >
          Monthly Report
        </li>
        <li
          className={`nav-link ${location.pathname === "/ActivityGoalsReport" ? "active" : ""}`}
          onClick={() => navigate("/ActivityGoalsReport")}
        >
          Activity Goals Report
        </li>

        <li
          className={`nav-link ${location.pathname === "/ActivitiesReport" ? "active" : ""}`}
          onClick={() => navigate("/ActivitiesReport")}
        >
          Activities Report
        </li>

        <li
          className={`nav-link ${location.pathname === "/TraceReport" ? "active" : ""}`}
          onClick={() => navigate("/TraceReport")}
        >
          Trace Report
        </li>
        <li
          className={`nav-link ${location.pathname === "/MonthlyPortfolioReport" ? "active" : ""}`}
          onClick={() => navigate("/MonthlyPortfolioReport")}
        >
          Monthly Rollup Report
        </li>

        <li
          className={`nav-link ${location.pathname === "/RFPReport" ? "active" : ""}`}
          onClick={() => navigate("/RFPReport")}
        >
          RFP Grid
        </li>
        <li
          className={`nav-link ${location.pathname === "/LNRGridTable" ? "active" : ""}`}
          onClick={() => navigate("/LNRGridTable")}
        >
          LNR Grid
        </li>
        <li
          className={`nav-link ${location.pathname === "/GroupGridTable" ? "active" : ""}`}
          onClick={() => navigate("/GroupGridTable")}
        >
          Group Grid
        </li>
        <li
          className={`nav-link ${
            location.pathname === "/MeetingCateringGridTable" ? "active" : ""
          }`}
          onClick={() => navigate("/MeetingCateringGridTable")}
        >
          Meeting & Catering Grid
        </li>
        <li
          className={`nav-link ${
            location.pathname === "/OpportunitySalesFunnelReport" ? "active" : ""
          }`}
          onClick={() => navigate("/OpportunitySalesFunnelReport")}
        >
          Sales Funnel Report
        </li>
        <li
          className={`nav-link ${location.pathname === "/LostBusinessGridReport" ? "active" : ""}`}
          onClick={() => navigate("/LostBusinessGridReport")}
        >
          Lost Business Report
        </li>
        <li
          className={`nav-link ${location.pathname === "/GroupMonthViewReport" ? "active" : ""}`}
          onClick={() => navigate("/GroupMonthViewReport")}
        >
          Group Room Count(GRC)
        </li>
        <li
          className={`nav-link ${
            location.pathname === "/MeeingAndCateringMonthViewReport" ? "active" : ""
          }`}
          onClick={() => navigate("/MeeingAndCateringMonthViewReport")}
        >
          Meeting Room Booked
        </li>

        <li
          className={`nav-link ${
            location.pathname === "/OpportunityBySourceReport" ? "active" : ""
          }`}
          onClick={() => navigate("/OpportunityBySourceReport")}
        >
          Opportunity By Source
        </li>
        <li
          className={`nav-link ${
            location.pathname === "/MeetingAndCateringByDate" ? "active" : ""
          }`}
          onClick={() => navigate("/MeetingAndCateringByDate")}
        >
          Meeting & Catering by Date
        </li>
        <li
          className={`nav-link ${location.pathname === "/GroupPaceReport" ? "active" : ""}`}
          onClick={() => navigate("/GroupPaceReport")}
        >
          Group Pace Report
        </li>
        <li
          className={`nav-link ${location.pathname === "/MeetingPaceReport" ? "active" : ""}`}
          onClick={() => navigate("/MeetingPaceReport")}
        >
          Meeting Pace Report
        </li>
        <li
          className={`nav-link ${location.pathname === "/LeadReport" ? "active" : ""}`}
          onClick={() => navigate("/LeadReport")}
        >
          Lead Report
        </li>
        <li
          className={`nav-link ${location.pathname === "/AccountReport" ? "active" : ""}`}
          onClick={() => navigate("/AccountReport")}
        >
          Account Report
        </li>
        <li
          className={`nav-link ${location.pathname === "/ActualReport" ? "active" : ""}`}
          onClick={() => navigate("/ActualReport")}
        >
          Actual Report
        </li>
      </ul>
    </div>
  );
}
