import React from "react";
import { Button, Col, FloatingLabel, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Select from "react-select";
import PdfIcon from "../../Asset/Images/Icons/PdfIcon.svg";
import ExportIcon from "../../Asset/Images/Icons/exporticon.svg";
import PageLoader from "../../Component/Loader/PageLoader";
import GroupPaceReportModal from "../../Model/Reports/GroupPaceReportModal";
import {
  AppConfigData,
  CommonService,
  HandleFilters,
  HandleRemoveOtherFilters,
  ToasterError,
  fnCheckValidationOfObject,
} from "../../Services/CommonServices";
import {
  GroupPaceReportListExport,
  GroupPaceReportListExportPDF,
} from "../../Services/ReportServices";
import { getProjectTermByCategory } from "../../Services/Setup&Config/AdditionalSettingService";
import Unauthorized from "../Authentication/Unauthorized";
import ReportNavigations from "./ReportNavigations";
import GoalReport from "../../Asset/Images/Other/generate-report.png";
import { getEmailAssignToDropdownList } from "../../Services/LeftMenuServices";
import { GetHotelsListForReports } from "../../Services/Setup&Config/HotelService";

function GroupPaceReport() {
  let GroupPaceReport_page_rights = CommonService.getPageRight("GroupPaceReport");

  const dataSelector: any = useSelector((state: any) => state.session);
  const SelectedPropertyID: any = useSelector((state: any) => state.dashboard.SelectedPropertyID);
  const SelectedPropertyName: any = useSelector(
    (state: any) => state.dashboard.SelectedPropertyName
  );
  const userId: any = dataSelector.auth_UserDetail.U_UserID;
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const Token = dataSelector.access_Token;
  const hotelsData: any = useSelector((state: any) => state.header.Hotels);
  let currentYear = new Date().getFullYear();
  const [selectedYear, setselectedYear]: any = React.useState(currentYear);
  const [showModal, setshowModal] = React.useState(false);
  const [yearList, setYearList]: any = React.useState([]);
  const [Downloading, setDownloading] = React.useState(false);
  const [DownloadingPDF, setDownloadingPDF] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [selectedUsers, setSelectedUsers]: any = React.useState([]);
  const [usersList, setUsersList] = React.useState([]);
  const [hotelChangeLoading, sethotelChangeLoading] = React.useState(false); // Hotel Selection Loader State.
  const [selHotels, setSelHotels]: any = React.useState([]);
  let [PropertiesListData, setPropertiesListData]: any = React.useState({
    IsData: true,
    data: [],
  });
  const [ReportData, setReportData]: any = React.useState({
    propertyID: "",
    userIDs: "",
    propertyName: "Select property",
    year: "",
    prevYear: "",
    yearID: "",
    prevYearID: "",
    errors: {
      propertyID: "",
      userIDs: "",
      ValidationRules: [
        {
          FieldName: "propertyID",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
        {
          FieldName: "userIDs",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
      ],
    },
  });

  // let HotelsDropdown: any = [];

  // hotelsData !== undefined &&
  //   hotelsData !== null &&
  //   hotelsData.length > 0 &&
  //   hotelsData.map((item: any) => {
  //     HotelsDropdown.push({
  //       label: item.HotelCode + " " + item.PropertyName,
  //       value: item.PropertyID,
  //     });
  //   });

  let HotelsDropdown: any = [];

  PropertiesListData.data !== undefined &&
    PropertiesListData.data !== null &&
    PropertiesListData.data.length > 0 &&
    PropertiesListData.data.map((item: any) => {
      HotelsDropdown.push({
        label: item.HotelCode + " " + item.PropertyName,
        value: item.PropertyID,
      });
    });

  React.useEffect(() => {
    if (!JSON.parse(localStorage.getItem("groupPaceReportFilter"))) {
      HandleRemoveOtherFilters("groupPaceReportFilter");
      HandleFilters("groupPaceReportFilter", {
        pagePath: "/GroupPaceReport",
        pageName: "groupPaceReport",
        filters: [],
        isSort: [],
      });
    }
  }, []);

  React.useEffect(() => {
    if (SelectedPropertyName) {
      if (!SelectedPropertyID.includes(",")) {
        setSelHotels([
          {
            value: SelectedPropertyID,
            label: SelectedPropertyName,
          },
        ]);

        setReportData({
          ...ReportData,
          propertyID: SelectedPropertyID,
          propertyName: SelectedPropertyName,
          userIDs: "",
        });

        fetchUsersList(SelectedPropertyID);
      }
    }

    getHotelsList();

    fetchYearDropdown();
  }, [SelectedPropertyName, SelectedPropertyID]);

  async function getHotelsList() {
    let input: any = {
      clientID: clientID,
      propertyName: "",
      propertyCode: "",
      portfolio: "",
      primaryContactNo: "",
      primaryEmailID: "",
      propertyStatus_Term: "Active",
      hotelCode: "",
      city: "",
      userID: userId,
    };
    setPropertiesListData({ IsData: true, data: [] });
    let res: any = await GetHotelsListForReports(input, Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null) {
        if (res.data.data !== undefined && res.data.data !== null) {
          setPropertiesListData({ IsData: false, data: res.data.data.dataList1 });
        } else {
          setPropertiesListData({ IsData: false, data: [] });
        }
      } else {
        setPropertiesListData({ IsData: false, data: [] });
      }
    } else {
      setPropertiesListData({ IsData: false, data: [] });
    }
  }

  async function fetchYearDropdown() {
    let yearInput = {
      clientID: clientID,
      category: "year",
    };
    setLoading(true);
    let resYear = await getProjectTermByCategory(yearInput, Token);

    if (resYear.status === 200) {
      if (resYear.data.success) {
        if (resYear.data.data !== undefined && resYear.data.data) {
          if (
            resYear.data.data.dataList1 !== undefined &&
            resYear.data.data.dataList1 &&
            resYear.data.data.dataList1.length
          ) {
            let yrArray: any = [];

            let tempPrevID: any;
            resYear.data.data.dataList1.forEach((itm) => {
              yrArray = [
                ...yrArray,
                {
                  value: itm.TermID,
                  label: itm.TermCode,
                },
              ];
              setSelectedUsers([]);
              if (selectedYear.toString() === itm.TermCode) {
                setReportData({
                  ...ReportData,
                  ["userIDs"]: "",
                  ["year"]: itm.TermCode,
                  ["yearID"]: itm.TermID,
                  ["propertyID"]: SelectedPropertyID.includes(",") ? "" : SelectedPropertyID,
                  ["propertyName"]:
                    SelectedPropertyName === "" || SelectedPropertyName === "All"
                      ? "Select Property"
                      : SelectedPropertyName,
                });
              }
            });

            setYearList(yrArray);
            setLoading(false);
          }
        }
      } else {
        setLoading(false);
      }
    }
  }

  const fetchUsersList = async (data_ids) => {
    let input = {
      clientID: clientID,
      propertyIDs: data_ids,
    };
    let res: any = await getEmailAssignToDropdownList(input, Token);
    if (res.data.success) {
      if (res.data.data !== null && res.data.data !== undefined && res.data.data !== "") {
        let userListDataToAppend: any = [
          {
            label: "All Users",
            value: "All Users",
          },
        ];
        res.data.data.dataList1.forEach((res_data) => {
          userListDataToAppend = [
            ...userListDataToAppend,
            {
              label: res_data.UserName,
              value: res_data.UserID,
            },
          ];
        });
        setUsersList(userListDataToAppend);
      } else {
        setUsersList([]);
      }
    } else {
      setUsersList([]);
    }
    sethotelChangeLoading(false);
  };

  async function hamdleGenerateReport() {
    let tempPrevYear: any;
    let tempPrevID: any;
    tempPrevYear = selectedYear - 1;

    yearList.length &&
      yearList.map((itm) => {
        if (tempPrevYear.toString() === itm.label) {
          tempPrevID = itm.value;
          tempPrevYear = itm.label;
        }
      });

    let obj = fnCheckValidationOfObject(ReportData);
    obj.obj.prevYear = tempPrevYear;
    obj.obj.prevYearID = tempPrevID;
    setReportData({
      ...obj.obj,
    });

    if (obj.isValid) {
      setshowModal(true);
    }
  }

  async function GenerateExcel() {
    let tempPrevYear: any;
    let tempPrevID: any;
    tempPrevYear = selectedYear - 1;

    yearList.length &&
      yearList.map((itm) => {
        if (tempPrevYear.toString() === itm.label) {
          tempPrevID = itm.value;
          tempPrevYear = itm.label;
        }
      });

    let obj = fnCheckValidationOfObject(ReportData);
    obj.obj.prevYear = tempPrevYear;
    obj.obj.prevYearID = tempPrevID;

    setReportData({
      ...obj.obj,
    });

    if (obj.isValid) {
      // Previous Request :
      // let input: any = {
      //   propertyID: ReportData.propertyID,
      //   yearID: ReportData.yearID,
      // };

      let input: any = {
        propertyID: ReportData.propertyID,
        yearID: ReportData.yearID,
        userIDs: ReportData.userIDs,
      };

      setDownloading(true);
      let res: any = await GroupPaceReportListExport(input, Token);
      if (res.data.success) {
        if (res.data !== undefined && res.data !== null && res.data !== "") {
          if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
            window.open(AppConfigData.APIBasePath + res.data.data);
            setDownloading(false);
          } else {
            ToasterError(res.data.message);
            setDownloading(false);
          }
        } else {
          ToasterError(res.data.message);
          setDownloading(false);
        }
      } else {
        ToasterError("Something Went Wrong.");
        setDownloading(false);
      }
    }
  }

  async function exportPDF() {
    let tempPrevYear: any;
    let tempPrevID: any;
    tempPrevYear = selectedYear - 1;

    yearList.length &&
      yearList.map((itm) => {
        if (tempPrevYear.toString() === itm.label) {
          tempPrevID = itm.value;
          tempPrevYear = itm.label;
        }
      });

    let obj = fnCheckValidationOfObject(ReportData);
    obj.obj.prevYear = tempPrevYear;
    obj.obj.prevYearID = tempPrevID;

    setReportData({
      ...obj.obj,
    });

    if (obj.isValid) {
      // Previous Request :
      // let input: any = {
      //   propertyID: ReportData.propertyID,
      //   yearID: ReportData.yearID,
      // };

      let input: any = {
        propertyID: ReportData.propertyID,
        yearID: ReportData.yearID,
        userIDs: ReportData.userIDs,
      };

      setDownloadingPDF(true);
      let res: any = await GroupPaceReportListExportPDF(input, Token);
      if (res.data.success) {
        if (res.data !== undefined && res.data !== null && res.data !== "") {
          if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
            window.open(AppConfigData.APIBasePath + res.data.data);
            setDownloadingPDF(false);
          } else {
            ToasterError(res.data.message);
            setDownloadingPDF(false);
          }
        } else {
          ToasterError(res.data.message);
          setDownloadingPDF(false);
        }
      } else {
        ToasterError("Something Went Wrong.");
        setDownloadingPDF(false);
      }
    }
  }

  async function SelectedPropertyData(data: any) {
    sethotelChangeLoading(true);
    // let tempID: any = data.value;

    if (data) {
      setReportData({
        ...ReportData,
        propertyID: data.value,
        propertyName: data.label,
        userIDs: "",
      });
      fetchUsersList(data.value);
    } else {
      setReportData({
        ...ReportData,
        propertyID: "",
        propertyName: "",
      });
      setUsersList([]);
      setSelectedUsers([]);
    }
    setSelectedUsers([]);
  }

  return (
    <>
      <div className="report-page">
        <Row>
          <Col xxl={3} xl={3} lg={3} md={4}>
            <ReportNavigations />
          </Col>

          <Col xxl={9} xl={9} lg={9} md={8} className="mt-4 mt-lg-0">
            {GroupPaceReport_page_rights.is_view ? (
              !loading ? (
                <div className="custom-profile-tabs-col">
                  <Row>
                    <Col lg={12} className="mb-4">
                      <div
                        className={`${
                          ReportData.errors && ReportData.errors.propertyID && "red-border-masking"
                        }`}
                      >
                        <FloatingLabel label="">
                          <Select
                            placeholder="Select Property"
                            options={HotelsDropdown}
                            // value={{ value: ReportData.propertyID, label: ReportData.propertyName }}
                            value={HotelsDropdown.filter(
                              (itm) =>
                                ReportData.propertyID && itm.value.includes(ReportData.propertyID)
                            )}
                            onChange={(event: any) => {
                              setSelHotels(event);
                              SelectedPropertyData(event);
                              // setReportData({
                              //   ...ReportData,
                              //   ["propertyID"]: event.value,
                              //   ["propertyName"]: event.label,
                              // });
                              // fetchUsersList(event.value);
                            }}
                          ></Select>
                        </FloatingLabel>
                      </div>
                      <small style={{ color: "#dc3545" }}>
                        {ReportData.errors && ReportData.errors.propertyID}{" "}
                      </small>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} xl={12} className="mb-3">
                      <div
                        className={`${
                          ReportData?.errors && ReportData?.errors?.userIDs && "red-border-masking"
                        }`}
                      >
                        <FloatingLabel controlId="selectUser" label="">
                          <Select
                            styles={{
                              menu: (provided) => ({ ...provided, zIndex: 9999 }),
                            }}
                            name="colors"
                            placeholder="Select Users"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            isDisabled={false}
                            isMulti={true}
                            options={usersList}
                            value={selectedUsers}
                            onChange={(itm: any) => {
                              let tmpItems = itm;
                              let data = tmpItems.filter((item: any) => item.label === "All Users");
                              // For All user Selection
                              if (data.length) {
                                let userIds = "";
                                let tmpUsersList = [...usersList];
                                tmpUsersList.splice(data, 1);
                                tmpUsersList.forEach((id_user: any) => {
                                  userIds = userIds.length
                                    ? userIds + "," + id_user.value
                                    : id_user.value;
                                });

                                setReportData({
                                  ...ReportData,
                                  userIDs: userIds,
                                });
                                setSelectedUsers(tmpUsersList);
                              } else {
                                // For Single - Single Selection
                                let userIds = "";
                                tmpItems.forEach((id_user: any) => {
                                  userIds = userIds.length
                                    ? userIds + "," + id_user.value
                                    : id_user.value;
                                });
                                setReportData({
                                  ...ReportData,
                                  userIDs: userIds,
                                });
                                setSelectedUsers(tmpItems);
                              }
                            }}
                          />
                        </FloatingLabel>
                      </div>
                      <small style={{ color: "#dc3545" }}>
                        {ReportData?.errors && ReportData?.errors?.userIDs}{" "}
                      </small>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4} className="mb-4">
                      <FloatingLabel controlId="selectUser" label="">
                        <Select
                          value={yearList.filter((obj) => obj.label === selectedYear.toString())}
                          onChange={(itm: any) => {
                            setselectedYear(itm.label);
                            setReportData({
                              ...ReportData,
                              ["year"]: itm.label,
                              ["yearID"]: itm.value,
                            });
                          }}
                          options={yearList}
                          name="colors"
                          placeholder="Select Year"
                          className="basic-multi-select"
                          isDisabled={false}
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <Row>
                    <Modal.Footer className="border-0 justify-content-start">
                      <Button
                        variant="primary ms-0"
                        onClick={() => {
                          if (GroupPaceReport_page_rights.is_view) {
                            hamdleGenerateReport();
                          } else {
                            CommonService.unauthorizedAction();
                          }
                        }}
                      >
                        <span className="me-2">
                          <img src={GoalReport} alt="" />
                        </span>
                        Generate Report
                      </Button>
                      <Button
                        variant="primary ms-0"
                        onClick={() => {
                          if (GroupPaceReport_page_rights.is_export) {
                            GenerateExcel();
                          } else {
                            CommonService.unauthorizedAction();
                          }
                        }}
                        disabled={Downloading === true ? true : false}
                      >
                        <span className="me-2">
                          <img src={ExportIcon} alt="" />
                        </span>
                        Export Excel
                      </Button>

                      <Button
                        variant="primary ms-0"
                        disabled={DownloadingPDF === true ? true : false}
                        onClick={(e) => {
                          if (GroupPaceReport_page_rights.is_export) {
                            e.preventDefault();
                            exportPDF();
                          } else {
                            CommonService.unauthorizedAction();
                          }
                        }}
                      >
                        <span className="me-2">
                          <img src={PdfIcon} alt="" />
                        </span>
                        Export PDF
                      </Button>
                    </Modal.Footer>
                  </Row>
                </div>
              ) : (
                <Col xxl={9} xl={9} lg={9} md={8} className="mt-4 mt-lg-0">
                  <PageLoader />
                </Col>
              )
            ) : (
              <Unauthorized />
            )}
          </Col>
        </Row>
        {showModal && (
          <GroupPaceReportModal
            groupPaceReportData={ReportData}
            show={showModal}
            onHide={() => setshowModal(false)}
          />
        )}
      </div>
      {hotelChangeLoading ? (
        <div className="calender-loader-main">
          <PageLoader />
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default GroupPaceReport;
